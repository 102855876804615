import React, { ChangeEvent, FC, useState } from 'react';
import cn from 'classnames';
import Card from 'components/UI/Card';
import { FiChevronDown, FiSearch, FiX } from 'react-icons/fi';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Input from 'components/UI/Input/input';
// import Help from 'components/Help';
import Checkbox from 'components/UI/Checkbox/checkbox';
import InputSelectPartners from 'components/UI/InputSelectPartners';
import {
  TFormikInitialVal,
  TPartnerData,
  useFiltersData,
} from 'domains/Analysis/model/hooks/useFiltersData';
import { Field, FieldProps, FormikProvider } from 'formik';
// import { useUserTheme } from 'domains/user/model/selectors';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import Actions from '../Actions';
import LoadingCheckbox from '../Loading/LoadingCheckbox';
import i18n from '../../../../i18n';

type TFFiltersProp = {
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
  openFilers: boolean;
  setCurrentTarget: React.Dispatch<React.SetStateAction<string>>;
  onGetReport: () => void;
  disGetReport: boolean;
  checkCurrentColumn: () => void;
  fixed: boolean;
  // currentTarget: string;
};

const Filters: FC<TFFiltersProp> = ({
  setOpenFilters,
  openFilers,
  setCurrentTarget,
  onGetReport,
  disGetReport,
  checkCurrentColumn,
  fixed,
  // currentTarget,
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const {
    partnerOptions,
    targetingOption,
    formik,
    targetItemsListView,
    isLoadingTargetItems,
    search,
    setSearch,
    checkCheckboxCount,
    isCheckPartially,
    isCheckAll,
    generalCheckboxHandler,
    tabIndex,
    setTabIndex,
    targetItemsList,
  } = useFiltersData(setCurrentTarget, setOpenFilters, fixed, setIsCollapse);

  // const { getValueFromSettingForKey } = useUserTheme();

  return (
    <>
      <div
        className={cn(css.filters_plag, {
          _isFixedScroll: fixed,
        })}
      />
      <Card
        noHover
        className={cn(css.filters, {
          _isShow: openFilers,
          _isFixed: fixed,
        })}
      >
        <FormikProvider value={formik}>
          <form>
            <div
              className={cn(css.filters_header, {
                _openWidget: !isCollapse,
              })}
            >
              <div
                className={css.title}
                onClick={fixed ? () => null : () => setIsCollapse(!isCollapse)}
              >
                {!openFilers && (
                  <span className={css.filters_header_icon}>
                    <FiChevronDown size={24} />
                  </span>
                )}
                {i18n.t(`analysis_page.filters_title`)}
              </div>
              {openFilers && (
                <FiX
                  size={24}
                  className={cn(css.close, 'icon')}
                  onClick={() => setOpenFilters(false)}
                />
              )}
            </div>
            <div
              className={cn(css.filters_body, 'childrenWrapper', {
                _isClose: isCollapse,
              })}
            >
              <div className={css.row}>
                <div className={cn(css.field, css.partners)}>
                  <div className={css.field_label}>
                    {i18n.t(`users_page.settings_modal.parthner_title`)}
                  </div>
                  <InputSelectPartners<TFormikInitialVal, TPartnerData[]>
                    fieldName="partner_data"
                    formik={formik}
                    propsValues={partnerOptions}
                    isError={Boolean(
                      formik.errors.partner_data &&
                        !!formik.touched.partner_data,
                    )}
                    errorText={formik.errors?.partner_data}
                    placeholder={i18n.t(`analysis_page.placeholper_partner`)}
                    clearable
                    isVerticalStyle
                  />
                </div>
                <div className={cn(css.field, css.targets)}>
                  <div className={css.field_label}>
                    {i18n.t(`campaigns_page.targeting_word`)}
                  </div>
                  <Field name="key">
                    {({ form, field }: FieldProps) => (
                      <NewSelect
                        field={field}
                        form={form}
                        selectAttributes={{
                          options: targetingOption,
                          value: targetingOption.find(
                            (target) => target.value === formik.values.key,
                          ),
                        }}
                        viewStyle="Vertical"
                      />
                    )}
                  </Field>
                </div>
              </div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
                className={css.tabs}
              >
                <TabList className={css.tablist}>
                  <Tab>{i18n.t(`analysis_page.select_list`)}</Tab>
                  <Tab>
                    {i18n.t(`analysis_page.add_list`)}
                    {/* Временно убрали подсказку */}
                    {/* {getValueFromSettingForKey('HideHelp') && (
                      <Help
                        classname={css.help}
                        title=""
                        helpattributes={{
                          text:
                            currentTarget === 'au'
                              ? i18n.t(`analysis_page.help_au`)
                              : i18n.t(`analysis_page.help_dir`),
                          colorIcon: '#A9A9A9',
                        }}
                      />
                    )} */}
                  </Tab>
                </TabList>
                <TabPanel className={css.panel}>
                  <div className={css.creatives_header}>
                    <div className={css.total}>
                      <Checkbox
                        label={`${i18n.t(
                          `analysis_page.selected`,
                        )} ${checkCheckboxCount} ${i18n.t(
                          `analysis_page.of`,
                        )} ${targetItemsListView?.length || 0}`}
                        isPartiallyChecked={isCheckPartially}
                        inputAttributes={{
                          type: 'checkbox',
                          checked: isCheckAll,
                          onChange: generalCheckboxHandler,
                          disabled: !targetItemsListView,
                        }}
                      />
                    </div>
                    <div className={css.search}>
                      <div className={css.input}>
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder={i18n.t(`analysis_page.search`)}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div className={css.icon}>
                        <FiSearch size={24} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn(css.creatives_body, {
                      isLoading: isLoadingTargetItems,
                      isEmpty:
                        (targetItemsList === null && !isLoadingTargetItems) ||
                        targetItemsList?.length === 0 ||
                        (targetItemsListView?.length === 0 &&
                          !isLoadingTargetItems),
                    })}
                  >
                    {isLoadingTargetItems && <LoadingCheckbox />}
                    {targetItemsListView?.length !== 0 &&
                      !isLoadingTargetItems &&
                      targetItemsListView?.map((target) => (
                        <div className={css.creatives_item} key={target.key}>
                          <Checkbox
                            label={target.title}
                            inputAttributes={{
                              type: 'checkbox',
                              checked: formik.values.targets[
                                formik.values.key
                              ]?.some(i => i.includes(target.key)),
                              onChange: (e) => {
                                const currentItemsArr =
                                  formik.values.targets &&
                                  formik.values.targets[formik.values.key];
                                if (currentItemsArr) {
                                  if (e.target.checked) {
                                    formik.setFieldValue(
                                      `targets.${formik.values.key}`,
                                      [...currentItemsArr, `${target.key}-${target.title}`],
                                    );
                                    return;
                                  }
                                  formik.setFieldValue(
                                    `targets.${formik.values.key}`,
                                    currentItemsArr.filter(
                                      (targetItem) => !targetItem.includes(target.key),
                                    ),
                                  );
                                }
                              },
                            }}
                          />
                        </div>
                      ))}
                    {(targetItemsList === null ||
                      targetItemsList?.length === 0 ||
                      targetItemsListView?.length === 0 ||
                      targetItemsListView === null) &&
                      !isLoadingTargetItems && (
                        <div className={css.empty}>
                          <FiSearch size={24} color="#828282" />
                          <span>{i18n.t(`search_none.none_1`)}</span>
                        </div>
                      )}
                  </div>
                </TabPanel>
                <TabPanel className={css.panel}>
                  <Input
                    classname={css.list_field}
                    isTextarea
                    error={!!formik.errors?.ariaText}
                    errortext={formik.errors?.ariaText}
                    inputAttributes={{
                      style: {
                        minHeight: 136,
                        maxHeight: 300,
                        overflow: 'scroll',
                      },
                      placeholder: i18n.t(`analysis_page.placeholder_textarea`),
                      value: formik.values.ariaText,
                      onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('ariaText', e.target.value),
                    }}
                  />
                </TabPanel>
              </Tabs>
              <Actions
                onGetReport={onGetReport}
                disGetReport={disGetReport}
                checkCurrentColumn={checkCurrentColumn}
                submitOnClickHandler={() => {
                  formik.setFieldTouched('partner_data', true);
                  if (formik.isValid) {
                    formik.handleSubmit();
                  }
                }}
              />
            </div>
          </form>
        </FormikProvider>
      </Card>
    </>
  );
};

export default Filters;
