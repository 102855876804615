/* eslint-disable no-nested-ternary */
import { historyDescription } from 'domains/historyChanges/model/utils/historyDescription';
import { targetingDescription } from 'domains/historyChanges/model/utils/targetingDescription';
import {
  ICategories,
  IHistoryAlgorithmCpc,
  IHistoryCreativeData,
  IHistoryLimits,
  IHistoryLimitsStat,
  IHistoryReportsItems,
  TTargetingItem,
} from 'domains/historyChanges/types';
import { ILocalHistoryData } from '../types';

// Общая функция для заполнения массивов 'Включено' и 'Выключено'
export const fillOnOffArrs = (
  arr: [
    string,
    IHistoryReportsItems | Record<string, IHistoryReportsItems> | string,
  ],
  reportsOnArr: string[],
  reportsOffArr: string[],
  mode?: string,
  bidDataArr?: string[],
): void => {
  const reportsItemName =
    mode === 'reports'
      ? historyDescription[arr[0]]
        ? historyDescription[arr[0]]
        : 'Неизвестный отчёт'
      : arr[0];
  if (arr[0] === 'disallow_group') {
    const brandSafetyData = Object.entries(arr[1]);
    brandSafetyData.forEach((item) =>
      fillOnOffArrs(item, reportsOnArr, reportsOffArr),
    );
    return;
  }
  if (arr[1] === 'null') {
    reportsOffArr.push(reportsItemName);
    return;
  }
  if (typeof arr[1] !== 'string') {
    if (arr[1].is_enabled || arr[1].is_checked || arr[1].checked) {
      reportsOnArr.push(reportsItemName);
    }
    if (
      arr[1].is_enabled === false ||
      arr[1].is_checked === false ||
      arr[1].checked === false
    ) {
      reportsOffArr.push(reportsItemName);
    }
    if (bidDataArr) {
      const bidDataRow: string[] = [];
      if (arr[1].bid_rate || arr[1].bid_rate === 0) {
        bidDataRow.push(`BIDRATE - ${arr[1].bid_rate}`);
      }
      if (arr[1].hour || arr[1].hour === 0) {
        bidDataRow.push(`АКТ.Ч - ${arr[1].hour}`);
      }
      if (arr[1].bid_start || arr[1].bid_start === 0) {
        bidDataRow.push(`BID - ${arr[1].bid_start}`);
      }
      if (arr[1].maxbid || arr[1].maxbid === 0) {
        bidDataRow.push(`MAXBID - ${arr[1].maxbid}`);
      }
      if (bidDataRow.length) {
        bidDataArr.push(`${reportsItemName}: ${bidDataRow.join(', ')}`);
      }
    }
  }
};

// Функции расшифровки отчетов
export const transformeReportsRow = (
  obj: Record<string, IHistoryReportsItems> | string,
  onArr: string[],
  offArr: string[],
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  mode?: string,
  cleanArr?: string[],
  name?: string,
): void => {
  if (mode === 'targetings' && name) {
    if (obj === 'null' && cleanArr) {
      cleanArr.push(`"${targetingDescription[name] ?? name}"\n`);
      return;
    }
    const onTargetingArr: string[] = [];
    const offTargetingArr: string[] = [];
    const bidDataArr: string[] = [];
    const targetingDataArr: [string, IHistoryReportsItems][] =
      Object.entries(obj);
    targetingDataArr.forEach((item) =>
      fillOnOffArrs(item, onTargetingArr, offTargetingArr, mode, bidDataArr),
    );
    const returnChangedBidsOrHourKeys = (
      targetData: [string, IHistoryReportsItems][],
    ): string[][] => {
      const bidChanges = [];
      const hourChanges = [];
      for (let i = 0; i <= targetData.length; i += 1) {
        if (targetData[i]) {
          if (
            Object.keys(targetData[i][1]).includes('bid_start') ||
            Object.keys(targetData[i][1]).includes('bid_rate') ||
            Object.keys(targetData[i][1]).includes('maxbid')
          ) {
            bidChanges.push(targetData[i][0]);
          }
          if (Object.keys(targetData[i][1]).includes('hour')) {
            hourChanges.push(targetData[i][0]);
          }
        }
      }
      return [bidChanges, hourChanges];
    };
    const [bidChanges, hourChanges] =
      returnChangedBidsOrHourKeys(targetingDataArr);
    if (onTargetingArr.length || offTargetingArr.length || bidDataArr.length) {
      historyDataForLocalHistory.push({
        key: '',
        value: `В таргете "${targetingDescription[name] ?? name}:" ${
          bidChanges.length ? `\nИзменен BID: ${bidChanges.join(', ')},` : ''
        }${
          hourChanges.length
            ? `\nИзменен АКТ.Ч: ${hourChanges.join(', ')},`
            : ''
        }${
          onTargetingArr.length
            ? `\nВключены элементы: ${onTargetingArr.join(', ')},`
            : ''
        }${
          offTargetingArr.length
            ? `\nВыключены элементы: ${offTargetingArr.join(', ')},`
            : ''
        }`,
      });
      resultChangesArr.push(
        `В таргете "${targetingDescription[name] ?? name}":
                ${
                  onTargetingArr.length
                    ? `Включены элементы: ${onTargetingArr.join(', ')},`
                    : ''
                }${
          offTargetingArr.length
            ? `Выключены элементы: ${offTargetingArr.join(', ')},`
            : ''
        }${bidDataArr.join(',')}`.trim(),
      );
      return;
    }
  }
  const reportsDataArr: [string, IHistoryReportsItems][] = Object.entries(obj);
  reportsDataArr.forEach((item) => fillOnOffArrs(item, onArr, offArr, mode));
};

// функции расшифровки претаргетов и бренд сефти
export const fillBrandSafetyRu = (
  item: [string, IHistoryReportsItems],
  arr: string[],
): number =>
  arr.push(
    item[1].checked
      ? 'Ротируем на сайты, где встретился русский текст'
      : 'Ротируем на все сайты',
  );

// Функции расшифровки таргетов
export const fillClearedTargetingsArr = (
  arr: string[],
  targetName: string,
): void => {
  arr.push(`"${targetName}"`);
};

export const transformTargetingsData = (
  tData: [string, string | TTargetingItem],
  onTargetsArr: string[],
  offTargetsArr: string[],
  cleanArr: string[],
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  invertMode?: string[],
  onInheritSettings?: string[],
  offInheritSettings?: string[],
): void => {
  if (typeof tData[1] !== 'string') {
    if (
      invertMode &&
      (tData[1].settings?.is_invert_mode ||
        tData[1].settings?.is_invert_mode === false)
    ) {
      invertMode.push(
        `Для таргета "${
          targetingDescription[tData[0]] ?? tData[0]
        }" включен режим ${
          tData[1].settings?.is_invert_mode ? 'blacklist' : 'whitelist'
        }`,
      );
    }
    if (
      onInheritSettings &&
      offInheritSettings &&
      (tData[1].settings?.use_inherit_settings ||
        tData[1].settings?.use_inherit_settings === false)
    ) {
      if (tData[1].settings?.use_inherit_settings) {
        onInheritSettings.push(
          `"${targetingDescription[tData[0]] ?? tData[0]}"`,
        );
      } else {
        offInheritSettings.push(
          `"${targetingDescription[tData[0]] ?? tData[0]}"`,
        );
      }
    }
    if (tData[1].items) {
      transformeReportsRow(
        tData[1].items,
        onTargetsArr,
        offTargetsArr,
        historyDataForLocalHistory,
        resultChangesArr,
        'targetings',
        cleanArr,
        tData[0],
      );
    }
  }
  if (tData[1] === 'null') {
    const targetName = targetingDescription[tData[0]]
      ? targetingDescription[tData[0]]
      : tData[0];
    fillClearedTargetingsArr(cleanArr, targetName);
  }
};

// заполнение массива тегов
export const fillTagsArr = (item: string[], arr: string[]): void => {
  if (item[1]) {
    arr.push(
      item[1] === 'null'
        ? `Удален тег: ${item[0]}`
        : `Добавлен тег: ${item[0]}`,
    );
  }
};

// функции расшифровки лимитов
export const transformeLimitsRow = (
  obj: IHistoryLimitsStat,
  key: string,
  translate: string,
): string => {
  if (obj[key])
    return `${translate}: ${obj[key] === 'null' ? '-' : `${obj[key]}`},`;
  return '';
};

export const transformeLimitsRowData = (
  obj: IHistoryLimits,
  key: string,
  limitsTitle?: string,
): string => {
  if (obj) {
    if (key === 'use_adriver_frequency_cap' && obj[key])
      return obj[key] === 'null'
        ? 'Выключено ограничение частоты показа Adriver'
        : 'Включено ограничение частоты показа Adriver';
    if (obj[key] === 'null') return `${limitsTitle}: - \n`;
    if (obj[key]) {
      return `${limitsTitle}: ${transformeLimitsRow(
        obj[key],
        'hour',
        'Час',
      )} ${transformeLimitsRow(obj[key], 'day', 'День')} ${transformeLimitsRow(
        obj[key],
        'total',
        'Всего',
      )}\n`;
    }
  }
  return '';
};

// расшифровка категорий
export const categoriesDescription = (
  categData: ICategories,
  resultChangesArr: string[],
): ILocalHistoryData[] => {
  const categoriesArr = [];
  if (categData.iab) {
    resultChangesArr.push(`IAB: ${categData.iab}`);
    categoriesArr.push({
      key: 'IAB',
      value: `${categData.iab}`,
    });
  }
  if (categData.is_animated || categData.is_animated === false) {
    resultChangesArr.push(
      `Анимация: ${categData.is_animated ? 'включена' : 'выключена'}`,
    );
    categoriesArr.push({
      key: 'Анимация',
      value: `${categData.is_animated ? 'включена' : 'выключена'}`,
    });
  }
  if (categData.violence_rank || categData.violence_rank === 0) {
    resultChangesArr.push(
      `Тип жести: ${historyDescription.violence[categData.violence_rank]}`,
    );
    categoriesArr.push({
      key: 'Тип жести',
      value: `${historyDescription.violence[categData.violence_rank]}`,
    });
  }
  if (categData.erotic_rank || categData.erotic_rank === 0) {
    resultChangesArr.push(
      `Тип эротики: ${historyDescription.erotic[categData.erotic_rank]}`,
    );
    categoriesArr.push({
      key: 'Тип эротики',
      value: `${historyDescription.erotic[categData.erotic_rank]}`,
    });
  }
  return categoriesArr;
};

// расшифровка creative data объекта
export const creativeDataDescription = (
  obj: IHistoryCreativeData,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
): void => {
  const creativeData = Object.entries(obj);
  creativeData.forEach((item) => {
    if (historyDescription.dataValues[item[0]]) {
      if (item[0] === 'use_vpaid') {
        historyDataForLocalHistory.push({
          key: `Галка ${historyDescription.dataValues[item[0]]}`,
          value: item[1] ? 'включена' : 'выключена',
        });
        resultChangesArr.push(
          `Галка ${historyDescription.dataValues[item[0]]} ${
            item[1] ? 'включена' : 'выключена'
          }`,
        );
        return;
      }
      if (item[0] === 'html_code') {
        resultChangesArr.push(
          `${historyDescription.dataValues[item[0]]}: ${
            item[1] === 'changed' ? 'изменён' : item[1]
          }`,
        );
        historyDataForLocalHistory.push({
          key: `${historyDescription.dataValues[item[0]]}`,
          value: `${item[1] === 'changed' ? 'изменён' : item[1]}`,
        });
        return;
      }
      resultChangesArr.push(
        `${historyDescription.dataValues[item[0]]}: ${
          item[1] ? item[1] : 'Поле очищено'
        }`,
      );
      historyDataForLocalHistory.push({
        key: `${historyDescription.dataValues[item[0]]}`,
        value: `${item[1] ? item[1] : 'Поле очищено'}`,
      });
    }
  });
};

// расшифровка algorithm_cpc
export const algorithmCpcDescription = (
  obj: IHistoryAlgorithmCpc,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
): void => {
  const algorithmCpcData = Object.entries(obj);
  const value: any[] = [];
  algorithmCpcData.forEach((item) => {
    if (item[0] === 'id') {
      value.push(historyDescription.cpcData[item[1]]);
      resultChangesArr.push(historyDescription.cpcData[item[1]]);
      return;
    }
    if (historyDescription.cpcData[item[0]]) {
      value.push(`${historyDescription.cpcData[item[0]]}: ${item[1]}`);
      resultChangesArr.push(
        `${historyDescription.cpcData[item[0]]}: ${item[1]}`,
      );
    }
  });
  historyDataForLocalHistory.push({
    key: `Алгоритм оптимизации`,
    value,
  });
};
