import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-use';
import { setTempAccessParentDefault } from 'domains/search/model/reducer';
import { fetchParentList } from 'domains/search/model/actions';
import { useIsGroup } from '../../domains/user/hooks';
import { TID } from '../../types/general';
import css from './styles.module.scss';
import { useUserInfo } from '../../domains/user/model/selectors';
import {
  useSearchAccessParent,
} from '../../domains/search/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';

type TIsDisable = {
  isDisable: boolean;
};

type Props = {
  children: ({ isDisable }: TIsDisable) => JSX.Element;
  blockBreadcrumbsQuery?: boolean;
};

const AccessUserRight: FC<Props> = ({ children, blockBreadcrumbsQuery }) => {
  const dispatch = useDispatchApp();
  // router
  const location = useLocation();
  const idAgency = new URLSearchParams(location.search).get('agency_id');
  const idClient = new URLSearchParams(location.search).get('client_id');
  const {
    params: { id },
  } = useRouteMatch<TID>();

  // store
  const { isOwner, isSelfUser } = useIsGroup();
  const { data: user, LTU: userLTU } = useUserInfo();

  const { data: parentList, LTU } = useSearchAccessParent();

  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [isDisable, setDisable] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  const access = () => {
    if (!isSelfUser && !isOwner && user) {
      setMessage(t('errors.err_access.owner'));
      return setDisable(() => true);
    }
    if (user && user.partner.status === 'ARCHIVE') {
      setMessage(
        t(`errors.err_access.${user.partner.type.toLocaleLowerCase()}`),
      );
      return setDisable(() => true);
    }

    const keys = (parentList && Object.keys(parentList)) || [];

    if (keys.length && parentList) {
      keys.forEach((key) => {
        if (key === 'Campaign') {
          if (parentList.Campaign.status === 'ARCHIVE') {
            setMessage(() => t(`errors.err_access.${key.toLocaleLowerCase()}`));
            setDisable(() => true);
          }
        }
        if (key === 'Client') {
          if (parentList.Client.status === 'ARCHIVE') {
            setMessage(() => t(`errors.err_access.${key.toLocaleLowerCase()}`));
            setDisable(() => true);
          }
        }
        if (key === 'Agency') {
          if (parentList.Agency.status === 'ARCHIVE') {
            setMessage(() => t(`errors.err_access.${key.toLocaleLowerCase()}`));
            setDisable(() => true);
          }
        }
        if (key === 'Organization') {
          if (parentList.Organization.status === 'ARCHIVE') {
            setMessage(() => t(`errors.err_access.${key.toLocaleLowerCase()}`));
            setDisable(() => true);
          }
        }
      });
    } else {
      setDisable(false);
    }
  };

  useEffect(() => {
    if (!blockBreadcrumbsQuery) {
      if (id) {
        dispatch(fetchParentList({ xxhash: id }));
      }
      if (idAgency) {
        dispatch(fetchParentList({ xxhash: idAgency }));
      }
      if (idClient) {
        dispatch(fetchParentList({ xxhash: idClient }));
      }
    }
  }, [id, idClient, idAgency]);

  useEffect(() => {
    if (!id && !idClient && !idAgency) {
      setDisable(() => false);
      dispatch(setTempAccessParentDefault());
    }
    access();
  }, [LTU, userLTU, id, idClient, idAgency]);

  return (
    <div className={css.wrapper}>
      <div className={css.children}>{children({ isDisable })}</div>
      {isDisable && <div className={css.message}>{message}</div>}
    </div>
  );
};

export default AccessUserRight;
