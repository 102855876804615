import { TTargetingItem } from 'domains/historyChanges/types';
import { targetingDescription } from '../../utils/targetingDescription';
import { transformTargetingsData } from './utils';
import { ILocalHistoryData } from './types';

const isGlobalHistory = window.location.pathname.includes('/control_panel/history_changes');

export const describeTargetings = (
  obj: Record<string, TTargetingItem>,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  resultStatusArr: string[],
): void => {
  const targetingsData = Object.entries(obj);
  const onTargetsArr: string[] = [];
  const offTargetsArr: string[] = [];
  const cleanTargetArr: string[] = [];
  const invertMode: string[] = [];
  const onInheritSettings: string[] = [];
  const offInheritSettings: string[] = [];
  const targetingsNames = targetingsData.map((tData) =>
    targetingDescription[tData[0]] ? targetingDescription[tData[0]] : tData[0],
  );
  targetingsData.forEach((tData) =>
    transformTargetingsData(
      tData,
      onTargetsArr,
      offTargetsArr,
      cleanTargetArr,
      historyDataForLocalHistory,
      resultChangesArr,
      invertMode,
      onInheritSettings,
      offInheritSettings,
    ),
  );
  if (isGlobalHistory) {
    resultStatusArr.push(
      `${targetingsNames.length > 1 ? 'Таргетинги' : 'Таргетинг'}`,
    );
  }
  if (cleanTargetArr.length) {
    historyDataForLocalHistory.push({
      key: 'Таргет очищен',
      value: `${
        cleanTargetArr.length > 1
          ? cleanTargetArr.join(', ')
          : cleanTargetArr[0]
      }`,
    });
    resultChangesArr.push(
      `Таргет очищен: ${
        cleanTargetArr.length > 1
          ? cleanTargetArr.join(', ')
          : cleanTargetArr[0]
      }`,
    );
  }
  if (onInheritSettings.length) {
    historyDataForLocalHistory.push({
      key: '',
      value: `Для таргета ${onInheritSettings.join(
        ', ',
      )} используются настройки таргетинга кампании`,
    });
    resultChangesArr.push(
      `Для таргета ${onInheritSettings.join(
        ', ',
      )} используются настройки таргетинга кампании`,
    );
  }
  if (offInheritSettings.length) {
    historyDataForLocalHistory.push({
      key: ``,
      value: `Для таргета ${offInheritSettings.join(
        ', ',
      )} используются настройки таргетинга креатива`,
    });
    resultChangesArr.push(
      `Для таргета ${offInheritSettings.join(
        ', ',
      )} используются настройки таргетинга креатива`,
    );
  }
  if (invertMode.length) {
    historyDataForLocalHistory.push({
      key: `${targetingsNames.length > 1 ? 'Таргетинги' : 'Таргетинг'}`,
      value: `${invertMode.join(',\n')}`,
    });
    resultChangesArr.push(`${invertMode.join(',\n')}`);
  }
};
