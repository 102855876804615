import {
  IHistoryData,
  IHistoryReportsItems,
} from 'domains/historyChanges/types';
import { ILocalHistoryData } from './types';
import { historyDescription } from '../../utils/historyDescription';
import { fillBrandSafetyRu, fillOnOffArrs } from './utils';

export const describePretargetings = (
  name: string,
  pretargetData:
    | Record<
        string,
        IHistoryReportsItems | Record<string, IHistoryReportsItems>
      >
    | string,
  jsonData: IHistoryData,
  type: string,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  resultStatusArr: string[],
): void => {
  const dataObj = { key: '', value: '' };
  if (name === 'pretargeting') {
    resultStatusArr.push(historyDescription[name][type]);
    dataObj.key = `${historyDescription[name][type]}`;
    if (pretargetData === 'null' && jsonData.brand_safety === 'null') {
      dataObj.value = '\nИспользуются настройки претаргетинга кампании';
      historyDataForLocalHistory.push(dataObj);
      resultChangesArr.push('Используются настройки претаргетинга кампании');
      return;
    }
  } else {
    dataObj.key = historyDescription[name];
    resultStatusArr.push(historyDescription[name]);
    if (pretargetData === 'null' && jsonData.pretargeting === 'null') {
      return;
    }
  }
  const pretargetingRu: string[] = [];
  const onPretargeting: string[] = [];
  const offPretargeting: string[] = [];
  const dataArr = Object.entries(pretargetData);
  dataArr.forEach((item) =>
    item[0] === 'Сайты с русским текстом'
      ? fillBrandSafetyRu(
          item as [string, IHistoryReportsItems],
          pretargetingRu,
        )
      : fillOnOffArrs(item, onPretargeting, offPretargeting),
  );
  dataObj.value = `\n${
    pretargetingRu.length ? `${pretargetingRu.join(', ')}\n` : ''
  }${onPretargeting.length ? `Включен: ${onPretargeting.join(', ')}\n` : ''}${
    offPretargeting.length ? `Выключен: ${offPretargeting.join(', ')}\n` : ''
  }`;
  resultChangesArr.push(
    `${pretargetingRu.length ? `${pretargetingRu.join(', ')}\n` : ''}${
      onPretargeting.length ? `Включен: ${onPretargeting.join(', ')}\n` : ''
    }${
      offPretargeting.length ? `Выключен: ${offPretargeting.join(', ')}\n` : ''
    }`,
  );
  historyDataForLocalHistory.push(dataObj);
};
