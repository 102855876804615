import { IHistoryReports } from 'domains/historyChanges/types';
import { transformeReportsRow } from './utils';
import { ILocalHistoryData } from './types';

export const describeReports = (
  obj: IHistoryReports,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
): void => {
  const onReportsArr: string[] = [];
  const offReportsArr: string[] = [];
  if (obj.items)
    transformeReportsRow(
      obj.items,
      onReportsArr,
      offReportsArr,
      historyDataForLocalHistory,
      resultChangesArr,
      'reports',
    );
  let reportsInheritSettings = '';
  if (obj.use_inherit_settings || obj.use_inherit_settings === false) {
    reportsInheritSettings = obj.use_inherit_settings
      ? '\nВключено использование настроек клиента'
      : '\nВыключено использование настроек клиента';
  }
  historyDataForLocalHistory.push({
    key: 'Отчеты',
    value: `${
      onReportsArr.length
        ? `\nВключены отчеты: \n${onReportsArr.join('; ')},`
        : ''
    }${
      offReportsArr.length
        ? `\nВыключены отчеты: \n${offReportsArr.join('; ')},`
        : ''
    }${reportsInheritSettings}`,
  });
  resultChangesArr.push(
    `${
      onReportsArr.length
        ? `\nВключены отчеты: \n${onReportsArr.join('; ')},`
        : ''
    }${
      offReportsArr.length
        ? `\nВыключены отчеты: \n${offReportsArr.join('; ')},`
        : ''
    }${reportsInheritSettings}`,
  );
};
