import { IHistoryData, THistoryResponse } from 'domains/historyChanges/types';
import { ILocalHistoryData } from './types';

type IDescribeJsonItemProps = (
  item: [string, any],
  data: THistoryResponse,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  resultStatusArr: string[],
  action: string,
  type: string,
) => void;

// преобразуем полученный объект в массив из массивов [ключ, значение]
export const describeJsonData = (
  jsonData: IHistoryData,
  data: THistoryResponse,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  resultStatusArr: string[],
  describeJsonItemData: IDescribeJsonItemProps,
  action: string,
  type: string,
): void => {
  const itemData = Object.entries(jsonData);
  itemData.forEach((item) =>
    describeJsonItemData(
      item,
      data,
      historyDataForLocalHistory,
      resultChangesArr,
      resultStatusArr,
      action,
      type
    ),
  );
};
