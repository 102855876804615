import LabelField from 'components/UI/LabelField';
import { CampaignGeneralComponentProps } from 'domains/campaign/types';
import { Field, FieldProps } from 'formik';
import React, { memo, useEffect, useRef, useState } from 'react';
import { SelectField } from 'types/general';
import { selectDefault } from 'utils/selectDefault';
import { FiExternalLink } from 'react-icons/fi';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { NavLink } from 'react-router-dom';
import RevertBlock from 'components/RevertBlock';
import NewSelect from 'components/UI/NewSelect';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import i18n from '../../../../../../../i18n';
import css from '../../styles.module.scss';

type TContractFieldFieldProps = Pick<
  CampaignGeneralComponentProps,
  'formik'
> & {
  contractsOptions?: SelectField[];
  type: 'EDIT' | 'CREATE';
};

const ContractField: React.FC<TContractFieldFieldProps> = ({
  formik,
  contractsOptions,
  type,
}) => {
  const { endScrollingBlock } = useDetectBlockScroll(true);
  const [timer, setTimer] = useState<number | null>(null);
  const timerRef = useRef<any>(null);

  const isShowLink =
    type === 'EDIT' &&
    formik.values.contract_uuid !== null &&
    formik.values.contract_uuid !== 'null';

  const showContractTooltip = (): boolean => {
    if (
      (formik.values.contract_uuid === '' || !formik.values.contract_uuid) ||
      contractsOptions &&
      selectDefault(String(formik.values.contract_uuid), contractsOptions)
        .length
    ) {
      return true;
    }
    return false;
  };

  const timerToSubmit = (count: number) => {
    let counter = count;

    setTimer(counter);

    timerRef.current = setInterval(() => {
      if (counter > 0) {
        counter -= 1;
        setTimer(counter);
      } else {
        clearInterval(timerRef.current);
        formik.submitForm();
        setTimer(null);
      }
    }, 1000);
  };

  const onCancelTimer = () => {
    setTimer(null);
    clearInterval(timerRef.current);
    formik.setFieldValue('contract_uuid', null);
  };

  useEffect(
    () => () => {
      if (timerRef.current) {
        setTimer(null);
        clearInterval(timerRef.current);
        formik.submitForm();
      }
    },
    [],
  );

  return (
    <div>
      <LabelField label={i18n.t(`ord.contracts.one`)}>
        <Field name="contract_uuid">
          {({ field, form }: FieldProps) => (
            <div className={css.contract_link}>
              <TooltipPoratal
                hint={i18n.t(`campaigns_page.contract_not_av`)}
                hideHint={showContractTooltip()}
              >
                <NewSelect
                  field={field}
                  form={form}
                  selectAttributes={{
                    options: contractsOptions?.filter(
                      (contract) => contract.status === 'ACTIVE',
                    ),
                    placeholder: i18n.t(`ord.contracts.placeholder_search`),
                    isDisabled:
                      type === 'EDIT' &&
                      formik.values.contract_uuid !== '' &&
                      formik.values.contract_uuid !== null,
                    value:
                      contractsOptions &&
                      selectDefault(
                        String(formik.values.contract_uuid),
                        contractsOptions,
                      ),
                    closeMenuOnScroll:
                      !endScrollingBlock && endScrollingBlock !== null
                        ? () => true
                        : () => false,
                  }}
                  heightCustom={32}
                  onCustomChange={(val) => {
                    formik.setFieldValue('contract_uuid', val);
                    if (type === 'EDIT') {
                      timerToSubmit(5);
                    }
                  }}
                  viewStyle="Horizontal"
                />
              </TooltipPoratal>
              {isShowLink &&
                type === 'EDIT' &&
                formik.values.contract_uuid !== '' && (
                  <NavLink
                    to={`/main/contract/${formik.values.contract_uuid}/edit`}
                    target="_blank"
                  >
                    <TooltipPoratal
                      hint={i18n.t(`ord.contracts.open`)}
                    >
                      <FiExternalLink size={16} />
                    </TooltipPoratal>
                  </NavLink>
                )}
            </div>
          )}
        </Field>
      </LabelField>
      {timer ? (
        <RevertBlock counter={timer} onClick={() => onCancelTimer()} />
      ) : null}
    </div>
  );
};

export default memo(ContractField);
