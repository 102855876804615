import React, { FC } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import Loader from 'components/UI/Loader';
import { useIsGroup } from 'domains/user/hooks';
import Statistics from './Statistics';
import Links from './Links';
import ErrorReport from '../../../components/Errors/Reports';
import HeaderCampaign from '../HeaderCampaign';
import css from './styles.module.scss';
import { useCampaignAdditional } from './hooks';
import Pretargeting from '../../../components/Pretargeting';
import Special from './Special';

const Additional: FC = () => {
  const { isOwner } = useIsGroup();
  const {
    campaign,
    isLoading,
    title,
    preTargeting,
    isLoadingPreTarget,
    handlerSetPretargeting,
    error,
  } = useCampaignAdditional();

  if (error.isError) {
    return <ErrorReport error={error} />;
  }
  if (isLoading && preTargeting || isLoadingPreTarget || !preTargeting) {
    return <Loader />;
  }

  return (
    <div className={css.additional}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <HeaderCampaign campaign={campaign} isLoading={isLoading} />

      <div className={css.content}>
        <div className={cn(css.container, 'container')}>
          {isOwner && <Pretargeting
            handlerSave={handlerSetPretargeting}
            preTargeting={preTargeting}
          />}
          <Statistics />
          {isOwner && <Special />}
          {isOwner && <Links />}
        </div>
      </div>
    </div>
  );
};

export default Additional;
