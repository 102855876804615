import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { TStatusCampaign } from 'domains/campaign/types';
import { TCounteragentStatus } from 'domains/counteragent/model/types';
import { AppState } from '../../redux/rootReducer';
import { counteragentsState, delLastId, delLastStatus, setLastId, setLastStatus } from '../../domains/counteragents/model/reducer';

/** возврощает последний посещенный ид */
export function useHighlights(): string {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, counteragentsState['lastId']>(
    ({ counteragentsReducer }) => counteragentsReducer.lastId,
    shallowEqual,
  );
  const lastStatus = useSelector<AppState, counteragentsState['lastStatus']>(
    ({ counteragentsReducer }) => counteragentsReducer.lastStatus,
    shallowEqual,
  );

  useEffect(() => {
    if (!lastId || !lastStatus) return;
    const url = new URL(window.location.href);
    url.searchParams.set('highlights', lastId);
    url.searchParams.set('status', lastStatus);
    history.push(url.search);
  }, [lastId, lastStatus]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);

  return id;
}
/** устанавливает последний посещенный ид из сылки */
export function useSetLastIdCounteragent(status?: TCounteragentStatus): void {
  const dispatch = useDispatchApp();
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    if (!id || !status) return;
    dispatch(setLastId(id));
    dispatch(setLastStatus(status));
  }, [id, status]);
}
/** удаляет последний посещеный ид */
export function useDelLastIdCounteragent(): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
    dispatch(delLastStatus());
  }, []);
}

export function useGetStatusFromUrl(): TStatusCampaign {
  const [counteragentStatus, setCounteragentStatus] = useState<TStatusCampaign>('');
  const location = useLocation()

  useEffect(() => {
    const paramHash = new URLSearchParams(location.search)
    const status = paramHash.get('status') ?? '';
    const counteragentsStatus = ["", "STOPPED", "LAUNCHED", "DELETED", "ARCHIVE"]
    if (status && counteragentsStatus.includes(status)) {
      setCounteragentStatus(status as TStatusCampaign)
    }    
  }, [location]);
  return counteragentStatus;
}
