export const historyDescription = {
  ADD: {
    CAMPAIGN: 'Кампания создана',
    CREATIVE: 'Креатив создан',
  },
  CLONE: {
    CAMPAIGN: 'Создана копия кампании',
    CREATIVE: 'Создана копия креатива',
  },
  TARGET_CLONE: {
    CAMPAIGN: 'В кампанию скопированы таргеты',
    CREATIVE: 'В креатив скопированы таргеты',
  },
  MASS_EDIT: {
    CAMPAIGN: 'Массовое редактирование',
    CREATIVE: 'Массовое редактирование',
  },
  title: {
    CAMPAIGN: 'Название кампании',
    CREATIVE: 'Название креатива',
  },
  status: {
    CAMPAIGN: 'Статус',
    CREATIVE: 'Статус',
  },
  statusData: {
    CAMPAIGN: {
      STOPPED: 'Кампания остановлена',
      LAUNCHED: 'Кампания запущена',
      DELETED: 'Кампания удалена',
      ARCHIVE: 'Кампания добавлена в архив',
    },
    CREATIVE: {
      STOPPED: 'Креатив остановлен',
      LAUNCHED: 'Креатив запущен',
      DELETED: 'Креатив удален',
      ARCHIVE: 'Креатив добавлена в архив',
    },
  },
  limits: 'Лимиты',
  pay_type: 'Модель оплаты',
  pay_price: 'Стоимость',
  date_start: 'Дата начала кампании',
  date_end: 'Дата окончания кампании',
  advertiser_name: 'Имя рекламодателя',
  method_recalculating_statistics: 'Метод пересчета статистики',
  RAW_STAT: 'По RAW STAT',
  CAMPAIGN_TYPE: 'По типу кампании',
  COEFFICIENTS: 'По коэффициентам',
  DSP_CALCULATION: 'По клиентскому расходу из DSP',
  TD_CALCULATION: 'По клиентскому расходу из TD',
  MAX_FIX_CPC_MARGINALITY: 'Максимальная цена клика',
  cpa_optimizations: 'Расширенная оптимизация',
  external_ids: 'Внешний ID',
  check_ERID: 'Проверка ERID',
  container: {
    cid: 'Идентификатор контейнера',
    save_strk_to_cookie:
      'Сохранять идентификатор клика (strk) в cookie при редиректе',
  },
  reports: 'Отчеты',
  day: 'Общий отчёт',
  day_techfee: 'Общий отчёт. Разбивка по tech fee',
  socdem: 'Социально-демографический отчёт',
  geo_city: 'Отчёт по гео',
  shownum: 'Отчёт по частоте показов',
  'nmi-integration-src': 'NMI отчёт',
  integration: 'Интегрированный отчёт',
  extended: 'Расширенный отчёт',
  yandex: 'Яндекс. Метрика. Cводный отчёт',
  extended_report: 'Данные для расширенного отчета',
  advanced_optimizations: 'Алгоритм оптимизации',
  targetings: 'Таргетинг',
  pretargeting: {
    CAMPAIGN: 'Претаргетинг кампании',
    CREATIVE: 'Претаргетинг креатива',
  },
  brand_safety: 'Brand safety',
  additional_metrics: 'Дополнительная метрика: \nКлики',
  statistic_coefficient: 'Коэффициенты',
  aggregation_tag: 'Агрегация кампаний',
  dashboard_data: 'DASHBOARD',
  tags: {
    CAMPAIGN: 'Теги кампании',
    CREATIVE: 'Теги креатива',
  },
  categories: 'Категории',
  violence: {
    0: 'Нет',
    1: 'Лайт',
    2: 'Средне',
    3: 'Мегажесть',
  },
  erotic: {
    0: 'Точно без секса',
    1: 'Части тела',
    2: 'Легкая эротика',
    3: 'Тяжелая эротика',
    4: 'Порно',
  },
  language: 'Язык',
  size: 'Размер, PX',
  link: 'Ссылка для перехода',
  extension_link: 'Доп. ссылка для перехода',
  adomain: 'Конечный URL',
  pixel: 'Пиксель аудита',
  pixel_additional: 'Доп. пиксель для аудита',
  data: 'Данные креатива',
  dataValues: {
    html_code: 'HTML код',
    use_vpaid: '"Использовать VPAID"',
    title: 'Заголовок',
    text: 'Текст',
    imgMask: 'Изображение',
    iconMask: 'Иконка',
    duration: 'Продолжительность, сек',
    skip_duration: 'Продолжительность пропуска, сек',
    video_url: 'Видео (URL)',
    vast_url: 'Видео (URL XML-файл)',
    video_bitrate: 'Битрейт, Кбит/с',
    description: 'Описание',
    file_size: 'Размер файла',
    show: 'SHOW (URL ТРЕКЕРА ПОКАЗА)',
    show2: 'SHOW (ДОПОЛНИТЕЛЬНЫЙ URL ТРЕКЕРА ПОКАЗА)',
  },
  algorithm_cpc: 'Алгоритм оптимизации',
  config_values: 'Конфигурационные поля',
  cpcData: {
    default: 'Не выбран',
    max_ctr: 'Оптимизация по ctr',
    min: 'Минимальное значение',
    max: 'Максимальное значение',
  },
  SELF_SERVICE_MARKUP:'Наценка Self-service',
  // statistic_fields :'Столбцы статистики',
  lead_wait_time: 'Время жизни клика',
  ord_name: 'Название в ОРД',
  offer_title: 'Оффер',
  bundle: 'Bundle',
  // hidden_statistic: 'Скрыть реальную статистику',
  contract_uuid: 'Изначальный договор'
};
