import { FormikErrors } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { PSetGroup } from 'domains/client/types';
import { TCampaignForm } from '../../types';
import i18n from '../../../../i18n';

type TInput = {
  total?: null | string;
  day?: null | string;
  hour?: null | string;
};

export function isTotal(obj: TInput): obj is { total: null | string } {
  return Object.prototype.hasOwnProperty.call(obj, 'total');
}

type TFValidate = (values: TCampaignForm) => FormikErrors<TCampaignForm>;

type TGroupValidate = (values: PSetGroup) => FormikErrors<PSetGroup>;

export const validateFormCampaign: TFValidate = (values) => {
  const errors: FormikErrors<TCampaignForm> = {};

  if (!(values.title.length > 0)) {
    errors.title = i18n.t('errors.err45');
  }

  if (!(values.partner_xxhash.length > 0)) {
    errors.partner_xxhash = i18n.t('errors.err45');
  }

  if (values.pay_type === 'cpa') {
    if (!values.pay_optimization_field_id) {
      errors.pay_optimization_field_id = i18n.t('errors.err45');
    }
  }

  const testDate = (str: string) => /^\d{2}([./])\d{2}\1\d{4}$/.test(str);

  if (!testDate(moment(values.date_start).format('DD/MM/YYYY'))) {
    errors.date_start = i18n.t('campaigns_page.campaign_settings.date_hint.h2');
  }

  if (!testDate(moment(values.date_end).format('DD/MM/YYYY'))) {
    errors.date_end = i18n.t('campaigns_page.campaign_settings.date_hint.h2');
  }

  if (
    moment(values.date_start).format('DD/MM/YYYY') !==
      moment(values.date_end).format('DD/MM/YYYY') &&
    moment(values.date_end).isSameOrBefore(values.date_start, 'day')
  ) {
    errors.date_end = i18n.t('campaigns_page.campaign_settings.date_hint.h1');
  }

  const tabs = ['click', 'show', 'show_per_user', 'budget', 'budget_raw'];

  tabs.forEach((tab) => {
    if (tab in values.limits) {
      const { hour, day } = values.limits[tab];
      const obj = values.limits[tab];
      let total = 0;
      if (isTotal(obj)) {
        total = obj.total ? +obj.total : 0;
      }
      const input: {
        total: string | undefined;
        day: string | undefined;
        hour: string | undefined;
      } = {
        total: undefined,
        day: undefined,
        hour: undefined,
      };

      if (typeof hour === 'number') {
        input.hour =
          (hour && hour > day && day) || (hour && hour > total && total)
            ? 'true'
            : undefined;
      }
      if (typeof day === 'number') {
        input.day =
          (day && day < hour && hour) || (day && day > total && total)
            ? 'true'
            : undefined;
      }
      if (typeof total === 'number') {
        input.total =
          (total && total < hour && hour) || (total && total < day && day)
            ? 'true'
            : undefined;
      }

      if (Object.values(input).includes('true')) {
        if (errors.limits === undefined) {
          errors.limits = {};
        }
        errors.limits[tab] = input;
      }
    }
  });

  return errors;
};

export const validateSchemeCampaign = Yup.object().shape({
  partner_xxhash: Yup.string().required(
    i18n.t('clients_page.client_create.hint1'),
  ),
  container: Yup.object().shape({
    cid: Yup.string()
      .matches(/([a-fA-F]|[0-9]){8}\b/gi, 'errors.err47_container')
      .max(8, 'errors.limitsym'),
  }),
  pay_price: Yup.number().max(2147483647).nullable(),
  limits: Yup.object().shape({
    budget: Yup.object().shape({
      hour: Yup.number().integer().max(2147483647).nullable(),
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    click: Yup.object().shape({
      hour: Yup.number().integer().max(2147483647).nullable(),
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show: Yup.object().shape({
      hour: Yup.number().integer().max(2147483647).nullable(),
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show_per_user: Yup.object().shape({
      hour: Yup.number().integer().max(2147483647).nullable(),
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    budget_raw: Yup.object().shape({
      hour: Yup.number().integer().max(2147483647).nullable(),
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
  }),
  kktu_codes: Yup.string().test(
    'kktu_codes',
    i18n.t('errors.err_value'),
    (value) => {
      const regex = /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/g;
      if (value === undefined) return true;
      if (value) {
        return regex.test(value);
      }
      return false;
    },
  ),
});

export const validateFormGroup: TGroupValidate = (values) => {
  const errors: FormikErrors<PSetGroup> = {};

  if (!(values.title.length > 0)) {
    errors.title = i18n.t('errors.err45');
  }

  const tabs = ['click', 'show', 'show_per_user', 'budget'];

  tabs.forEach((tab) => {
    if (tab in values.limits) {
      const { day } = values.limits[tab];
      const obj = values.limits[tab];
      let total = 0;
      if (isTotal(obj)) {
        total = obj.total ? +obj.total : 0;
      }
      const input: {
        total: string | undefined;
        day: string | undefined;
      } = {
        total: undefined,
        day: undefined,
      };

      if (typeof day === 'number') {
        input.day = day && day > total && total ? 'true' : undefined;
      }
      if (typeof total === 'number') {
        input.total = total && total < day && day ? 'true' : undefined;
      }

      if (Object.values(input).includes('true')) {
        if (errors.limits === undefined) {
          errors.limits = {};
        }
        errors.limits[tab] = input;
      }
    }
  });

  return errors;
};

export const validateSchemeGroup = Yup.object().shape({
  limits: Yup.object().shape({
    budget: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    click: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show_per_user: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
  }),
});
