import UploadImage from 'domains/uploads/UploadImage';
import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { getSizes } from 'domains/uploads/helpers';
import { TTypeHookProps } from 'domains/creative/types';
import EridWarning from 'components/EridWarning';
import { Trans } from 'react-i18next';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';
import { IUseTypeProp } from '../../../../hooks/useType';

type TTeaserProps = IUseTypeProp &
  TTypeHookProps & {
    setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
    ord_registration?: boolean;
  };

const index: FC<TTeaserProps> = ({
  formik: { values, errors, setFieldValue, handleChange, validateForm },
  erid,
  setIsDisableSaveButton,
  ord_registration,
}) => (
  <div className={css.form}>
    <UploadImage
      keyUploadFile="teaser"
      preview={getSizes(values.data.iconMask, values.data.iconFormats)}
      name="iconMask"
      setFieldValue={setFieldValue}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.icon_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoicon_word')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.teaser_formats1')}
          <br />
          {i18n.t('creative.creasmall_set.teaser_formats2')}
        </span>
      }
      hasEdit
      edit
      isData
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="teaser"
    />
    <UploadImage
      keyUploadFile="teaser"
      preview={getSizes(values.data.imgMask, values.data.imgFormats)}
      name="imgMask"
      setFieldValue={setFieldValue}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.image_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.teaser_formats3')}
          <br />
          {i18n.t('creative.creasmall_set.teaser_formats4')}
        </span>
      }
      hasEdit
      edit
      isData
      minResolution={100}
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="teaser"
    />

    <Input
      label={i18n.t('creative.creasmall_set.title_word')}
      showValueCount
      inputAttributes={{
        name: 'data.title',
        placeholder: i18n.t('creative.creasmall_set.title_word'),
        maxLength: 100,
        style: { height: 48 },
        value: values.data.title,
        onChange: handleChange,
      }}
    />

    <Input
      label={i18n.t('creative.creasmall_set.text_word')}
      isTextarea
      showValueCount
      inputAttributes={{
        name: 'data.text',
        placeholder: i18n.t('creative.creasmall_set.text_word'),
        maxLength: 200,
        style: { height: 128 },
        value: values.data.text,
        onChange: handleChange,
      }}
    />
    {ord_registration && (
      <Input
        label={i18n.t(`creative.general_description_label`)}
        isTextarea
        tooltip={{
          text: (
            <Trans
              i18nKey="creative.general_description_hint"
              components={{ br: <br /> }}
            />
          ),
          colorIcon: '#222222',
          sizeIcon: 20,
        }}
        showValueCount
        error={!!errors.data?.description}
        errortext={errors.data?.description}
        inputAttributes={{
          name: 'data.description',
          placeholder: i18n.t(`creative.general_description_place`),
          maxLength: 1024,
          value: values.data.description,
          onChange: handleChange,
        }}
      />
    )}
    {erid && (
      <>
        <Input
          label={erid.title}
          showValueCount
          error={!!errors.erid}
          errortext={errors.erid}
          inputAttributes={{
            name: 'erid',
            placeholder: erid.title,
            maxLength: 80,
            value: values.erid,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('erid', e.target.value.trim()),
          }}
        />
        {values.erid && (
          <EridWarning
            withAccept
            isChangeErid
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}
      </>
    )}
    <Input
      label="Bundle ID"
      showValueCount
      error={!!errors.data?.bundle}
      errortext={errors.data?.bundle}
      inputAttributes={{
        name: 'data.bundle',
        placeholder: i18n.t(`creative.enter_id`),
        maxLength: 80,
        value: values.data.bundle,
        onChange: handleChange,
      }}
    />
  </div>
);

export default index;
