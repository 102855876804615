import { ContractsList } from 'domains/contracts/model/types';
import { SelectField } from 'types/general';

export const transformContractList = (
  list: ContractsList,
): SelectField[] | undefined =>
    list?.map((c) => ({
    value: c.uuid,
    label: `${c.number} \u00B7 ${c.ord_title}`,
  }));
