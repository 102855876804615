import React, { FC, useState, CSSProperties } from 'react';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import cn from 'classnames';
import equal from 'deep-equal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FiAlertCircle, FiExternalLink } from 'react-icons/fi';
import { Trans, useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import Input from 'components/UI/Input/input';
import Tooltip from 'components/UI/Tooltip';
import EditCard from 'components/UI/EditCard';
import RadioGroup from 'components/UI/RadioGroup';
import { animated, useSpring } from 'react-spring';
import Help from 'components/Help';
import { NamedProps } from 'react-select';
import { SelectField } from 'types/general';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import checkSelectOptionsTranslation from 'utils/checkSelectOptionsTranslation';
import { useUserTheme } from 'domains/user/model/selectors';
import { isGardCompanionType } from 'domains/creative/creatives/hooks';
import InfoBlock from 'components/UI/InfoBlock';
import NewSelect from 'components/UI/NewSelect';
import { useIsGroup } from 'domains/user/hooks';
import { CreativeDetail, TCreativeHTML } from 'domains/creative/types';
import i18next from '../../../../../../i18n';
import css from './styles.module.scss';
import { useCreativeEditAdditional } from './hooks';
import PriceInput from '../../../../../../components/UI/PriceInput/input';
import {
  optionsCategoryIAB,
  optionsLanguage,
  optionsCategoryIABEng,
  optionsLanguageEng,
} from '../../../../model/shared';

type Props = {
  creative: CreativeDetail;
};

const Additional: FC<Props> = ({
  creative: {
    xxhash,
    pixel,
    pixel_additional,
    categories,
    partner_xxhash,
    type,
    link,
    adomain,
    language,
    data,
    algorithm_cpc,
    extension_link,
    config_values,
  },
}) => {
  const { isOwner } = useIsGroup();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const currentIABOptions = checkSelectOptionsTranslation(
    optionsCategoryIAB,
    optionsCategoryIABEng,
    currentLanguage,
  );
  const currentLangOptions = checkSelectOptionsTranslation(
    optionsLanguage,
    optionsLanguageEng,
    currentLanguage,
  );
  const HTMLCreoData = type === 'HTML' ? (data as TCreativeHTML) : null;

  const jsAudit = useGetFieldConfigValue('js_code_audit', type);
  const iURL = useGetFieldConfigValue('iurl', type);
  const clickAuditPixel = useGetFieldConfigValue('click_audit_pixel', type);

  const {
    client,
    isLoadingClient,
    formik,
    violence,
    sex,
    algorithmsSelect,
    onResetForm,
    helperError,
    selectDefault,
    helper,
    offerId,
    currentOfferTitle,
  } = useCreativeEditAdditional({
    xxhash,
    partner_xxhash,
    setEdit,
    adomain,
    show_html: isGardCompanionType(data) ? data.show_html : '',
    jsAudit,
    click_audit_pixel: clickAuditPixel,
    pixel_additional,
    pixel,
    language,
    categories,
    algorithm_cpc,
    link,
    iurl: iURL,
    edit,
    extension_link,
    HTMLCreoData,
    type,
    config_values,
  });

  const showBlock = useSpring<CSSProperties>(
    !(
      formik.values.algorithm_cpc.id === '1' ||
      formik.values.algorithm_cpc.id === 1
    )
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
        },
  );

  function JSCodeAuditHandler(e: React.ChangeEvent<HTMLTextAreaElement>) {
    formik.setFieldValue('js_code_audit', e.target.value);
  }

  const customOptions: NamedProps<SelectField, boolean>['components'] = {
    Option: ({ innerProps, innerRef, label }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={cn(css.customOptions, {
          _isSub: label.indexOf('-') > -1,
        })}
      >
        {label}
      </div>
    ),
  };
  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          onResetForm={onResetForm}
          hasErrors={Object.keys(formik.errors).length > 0}
          onSave={() => {
            formik.handleSubmit();
          }}
          notEqual={
            !equal(formik.values, {
              link,
              adomain,
              pixel,
              pixel_additional,
              categories,
              language,
            })
          }
          setEdit={setEdit}
          isAccessDenied={type === 'YANDEX'}
        >
          <h3 className={css.card__title}>
            {t('creative.create_creative.Additional_settings')}
            {getValueFromSettingForKey('HideHelp') && (
              <Help
                width={250}
                title={t('creative.create_creative.Additional_settings')}
                helpattributes={{
                  link: t('creative.creative_prompt.additional_settings.link'),
                  sizeIcon: 18,
                }}
              />
            )}
          </h3>

          <div className={css.card__form}>
            <Tabs>
              <TabList>
                <Tab
                  className={`react-tabs__tab ${
                    (!!formik.errors?.adomain ||
                      !!formik.errors?.link ||
                      !!formik.errors?.extension_link ||
                      !!formik.errors?.pixel ||
                      !!formik.errors?.pixel_additional ||
                      !!formik.errors?.js_code_audit ||
                      !!formik.errors?.data?.show_html) &&
                    'hasError'
                  }`}
                >
                  <span>{t('creative.create_creative.URL_settings')}</span>
                  {(!!formik.errors?.adomain ||
                    !!formik.errors?.link ||
                    !!formik.errors?.extension_link ||
                    !!formik.errors?.pixel ||
                    !!formik.errors?.pixel_additional ||
                    !!formik.errors?.js_code_audit ||
                    !!formik.errors?.data?.show_html) && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
                {type !== 'MULTI_FORMAT' && (
                  <Tab
                    className={`react-tabs__tab ${
                      !!formik.errors?.categories && 'hasError'
                    } ${!!formik.errors?.language && 'hasError'}`}
                  >
                    {formik.values.categories.iab === '24' && (
                      <FiAlertCircle
                        size={16}
                        color="#FF9C08"
                        className="warning-icon"
                      />
                    )}
                    <span>
                      {t('creative.create_creative.CategoriesAndLanguage')}
                    </span>
                    {(!!formik.errors?.categories ||
                      !!formik.errors?.language) && (
                      <FiAlertCircle size={16} className="alert-icon" />
                    )}
                  </Tab>
                )}
                {type !== 'MULTI_FORMAT' && type !== 'CLICKUNDER' && isOwner && (
                  <Tab
                    className={`react-tabs__tab ${
                      !!formik.errors?.algorithm_cpc && 'hasError'
                    }`}
                  >
                    <span>
                      {t('creative.create_creative.optimization.title')}
                    </span>
                    {!!formik.errors?.algorithm_cpc && (
                      <FiAlertCircle size={16} className="alert-icon" />
                    )}
                  </Tab>
                )}
              </TabList>
              <TabPanel>
                {offerId && currentOfferTitle && (
                  <InfoBlock
                    color="BLUE"
                    text={
                      <Trans
                        i18nKey="offers_page.use_offer"
                        values={{ currentOfferTitle }}
                        components={{ bold: <strong /> }}
                      />
                    }
                  />
                )}
                <div className={css.card__row}>
                  <div className={cn(css.card__col, 'full')}>
                    <Input
                      notField={edit}
                      isLink={!edit}
                      label={t('creative.create_creative.Navigation_link')}
                      error={!!formik.errors.link}
                      errortext={formik.errors.link}
                      helper={helper().link}
                      disabled={!!offerId}
                      inputAttributes={{
                        placeholder: t('creative.create_creative.Link'),
                        name: 'link',
                        value: formik.values.link,
                        onChange: formik.handleChange,
                      }}
                      theme="horizontal"
                      isTextarea
                      classname="exactly"
                    />
                  </div>
                </div>
                <div className={css.card__row} style={{ marginTop: '10px' }}>
                  <div className={cn(css.card__col, 'full')}>
                    <Tooltip
                      white
                      hintWidth={300}
                      title="{CAMP_ID}"
                      className={css.tooltip}
                    >
                      <h6 style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m1.t1')}
                      </h6>
                      <p style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m1.t2')}
                      </p>
                      <p style={{ marginBottom: 0, color: '#828282' }}>
                        https://www.example.com.ru/?camp_id=6596f2bb147e5638
                      </p>
                      <a
                        style={{
                          display: 'block',
                          marginTop: '16px',
                        }}
                        href="https://terratraf.readme.io/docs/td-general-macros"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </Tooltip>
                    &nbsp;
                    <Tooltip
                      white
                      hintWidth={300}
                      title="{CR_ID}"
                      className={css.tooltip}
                    >
                      <h6 style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m2.t1')}
                      </h6>
                      <p style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m2.t2')}
                      </p>
                      <p style={{ marginBottom: 0, color: '#828282' }}>
                        https://www.example.com.ru/?cr_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
                      </p>
                      <a
                        style={{
                          display: 'block',
                          marginTop: '16px',
                        }}
                        href="https://terratraf.readme.io/docs/td-general-macros"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </Tooltip>
                    &nbsp;
                    <Tooltip
                      white
                      hintWidth={300}
                      title="{SRC_ID}"
                      className={css.tooltip}
                    >
                      <h6 style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m3.t1')}
                      </h6>
                      <p style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m3.t2')}
                      </p>
                      <p style={{ marginBottom: 0, color: '#828282' }}>
                        https://www.example.com.ru/src_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
                      </p>
                      <a
                        style={{
                          display: 'block',
                          marginTop: '16px',
                        }}
                        href="https://terratraf.readme.io/docs/td-general-macros"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </Tooltip>
                    &nbsp;
                    <Tooltip
                      white
                      hintWidth={300}
                      title="{TR_KEY} "
                      className={css.tooltip}
                    >
                      <h6 style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m4.t1')}
                      </h6>
                      <p style={{ marginBottom: 0 }}>
                        {t('creative.create_creative.tizmacr.m4.t2')}
                      </p>
                      <p style={{ marginBottom: 0, color: '#828282' }}>
                        https://www.example.com.ru/?tr_key=CgjifzCW8PhxyRCEt7flBhj-4L3vBQ
                      </p>
                      <a
                        style={{
                          display: 'block',
                          marginTop: '16px',
                        }}
                        href="https://terratraf.readme.io/docs/td-general-macros"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </Tooltip>
                  </div>
                </div>
                {(type === 'HTML' ||
                  type === 'VIDEO_INLINE' ||
                  type === 'VIDEO_VAST' ||
                  /* COMPANION под вопросом */
                  type === 'COMPANION') && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        isLink={!edit}
                        label={t('creative.create_creative.Extension_link')}
                        error={!!formik.errors.extension_link}
                        errortext={formik.errors.extension_link}
                        helper={helper().extension_link}
                        inputAttributes={{
                          placeholder: t('creative.create_creative.Link'),
                          name: 'extension_link',
                          value: formik.values.extension_link,
                          onChange: formik.handleChange,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}
                {type === 'HTML' && clickAuditPixel && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        isLink={!edit}
                        label={t(`creative.${clickAuditPixel.field}`)}
                        error={!!formik.errors.click_audit_pixel}
                        errortext={formik.errors.click_audit_pixel}
                        helper={helper().click_audit_pixel}
                        inputAttributes={{
                          name: 'click_audit_pixel',
                          value: formik.values.click_audit_pixel || '',
                          placeholder: t('creative.create_creative.Link'),
                          onChange: formik.handleChange,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}
                {type !== 'MULTI_FORMAT' && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        isLink={!edit}
                        label={t('creative.create_creative.Domain')}
                        error={!!formik.errors.adomain}
                        errortext={formik.errors.adomain}
                        disabled={!!offerId}
                        inputAttributes={{
                          name: 'adomain',
                          placeholder: t('creative.create_creative.Link'),
                          value: formik.values.adomain,
                          onChange: formik.handleChange,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}

                {(type === 'HTML' ||
                  type === 'TEASER' ||
                  type === 'YANDEX') && (
                  <>
                    <div className={css.card__row}>
                      <div className={cn(css.card__col, 'full')}>
                        <Input
                          notField={edit}
                          isLink={!edit}
                          label={t('creative.create_creative.Pixel_audit')}
                          error={!!formik.errors.pixel}
                          errortext={formik.errors.pixel}
                          helper={helper().pixel}
                          disabled={!!offerId}
                          inputAttributes={{
                            name: 'pixel',
                            placeholder: t('creative.create_creative.Link'),
                            value: formik.values.pixel,
                            onChange: formik.handleChange,
                          }}
                          theme="horizontal"
                          isTextarea
                          classname="exactly"
                        />
                      </div>
                    </div>
                    <div className={css.card__row}>
                      <div className={cn(css.card__col, 'full')}>
                        <Input
                          notField={edit}
                          isLink={!edit}
                          label={t('creative.create_creative.Optional_pixel')}
                          error={!!formik.errors.pixel_additional}
                          errortext={formik.errors.pixel_additional}
                          helper={helper().pixel_additional}
                          inputAttributes={{
                            name: 'pixel_additional',
                            value: formik.values.pixel_additional,
                            placeholder: t('creative.create_creative.Link'),
                            onChange: formik.handleChange,
                          }}
                          theme="horizontal"
                          isTextarea
                          classname="exactly"
                        />
                      </div>
                    </div>
                  </>
                )}
                {type === 'COMPANION' && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        isLink={!edit}
                        label={t('creative.create_creative.show_html')}
                        error={!!formik.errors.data?.show_html}
                        errortext={formik.errors.data?.show_html}
                        helper={helper().show_html}
                        inputAttributes={{
                          name: 'data.show_html',
                          placeholder: t('creative.create_creative.Link'),
                          value: formik.values.data?.show_html,
                          onChange: formik.handleChange,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}
                {iURL && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        isLink={!edit}
                        label={iURL.field}
                        error={!!formik.errors.iurl}
                        errortext={formik.errors.iurl}
                        inputAttributes={{
                          placeholder: t('creative.create_creative.Link'),
                          name: 'iurl',
                          value: formik.values.iurl || '',
                          onChange: formik.handleChange,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}

                {type === 'HTML' && jsAudit && (
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Input
                        notField={edit}
                        label={t(`creative.${jsAudit.field}`)}
                        error={!!formik.errors.js_code_audit}
                        errortext={formik.errors.js_code_audit}
                        mode="JScode"
                        helper={helper().js_code_audit}
                        inputAttributes={{
                          name: 'js_code_audit',
                          placeholder: t(`creative.js_code`),
                          value: formik.values.js_code_audit || '',
                          onChange: JSCodeAuditHandler,
                        }}
                        theme="horizontal"
                        isTextarea
                        classname="exactly"
                      />
                    </div>
                  </div>
                )}
              </TabPanel>
              {type !== 'MULTI_FORMAT' && (
                <TabPanel>
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Field name="categories.iab">
                        {({ field, form }: FieldProps) => (
                          <NewSelect
                            label="IAB"
                            viewingOnly={!edit}
                            form={form}
                            field={field}
                            selectAttributes={{
                              name: 'categories.iab',
                              placeholder: t(
                                'creative.create_creative.select_from_list',
                              ),
                              options: currentIABOptions,
                              value: selectDefault(
                                `${formik.values.categories.iab}`,
                                currentIABOptions,
                              ),
                              customOptions,
                            }}
                            viewStyle="Vertical"
                          />
                        )}
                      </Field>
                      {formik.values.categories.iab === '24' && (
                        <div className={css.warning}>
                          <FiAlertCircle
                            size={16}
                            color="#FF9C08"
                            className="warning-icon"
                          />
                          <span>{t('errors.additional_cat_err')}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {isLoadingClient && (
                    <div className={css.loaderClient}>
                      <Loader />
                    </div>
                  )}
                  {client &&
                    client.is_animation_setting_enabled &&
                    type !== 'CLICKUNDER' && (
                      <div className={css.card__row}>
                        <div className={cn(css.card__col, 'full')}>
                          <RadioGroup
                            name="categories.is_animated"
                            label={t('creative.create_creative.animation')}
                            options={[
                              {
                                title: t('creative.create_creative.ani_yes'),
                                value: true,
                              },
                              {
                                title: t('creative.create_creative.ani_no'),
                                value: false,
                              },
                            ]}
                            setFieldValue={formik.setFieldValue}
                            checked={formik.values.categories.is_animated}
                            notField={!edit}
                          />
                        </div>
                      </div>
                    )}

                  {type !== 'CLICKUNDER' &&
                    client &&
                    (client.is_violence_setting_enabled ||
                      client.is_erotic_setting_enabled) && (
                      <div className={css.card__row}>
                        {client.is_violence_setting_enabled && (
                          <div className={css.card__col}>
                            <Field name="categories.violence_rank">
                              {({ field, form }: FieldProps) => (
                                <NewSelect
                                  label={t(
                                    'creative.create_creative.type_jest',
                                  )}
                                  form={form}
                                  viewingOnly={!edit}
                                  field={field}
                                  selectAttributes={{
                                    placeholder: t(
                                      'creative.create_creative.sel_type',
                                    ),
                                    options: violence,
                                    value: selectDefault(
                                      `${formik.values.categories.violence_rank}`,
                                      violence,
                                    ),
                                  }}
                                  viewStyle="Vertical"
                                />
                              )}
                            </Field>
                          </div>
                        )}
                        {client.is_erotic_setting_enabled && (
                          <div className={css.card__col}>
                            <Field name="categories.erotic_rank">
                              {({ field, form }: FieldProps) => (
                                <NewSelect
                                  label={t(
                                    'creative.create_creative.type_erotick',
                                  )}
                                  form={form}
                                  field={field}
                                  viewingOnly={!edit}
                                  selectAttributes={{
                                    placeholder: t(
                                      'creative.create_creative.sel_type',
                                    ),
                                    options: sex,
                                    value: selectDefault(
                                      `${formik.values.categories.erotic_rank}`,
                                      sex,
                                    ),
                                  }}
                                  viewStyle="Vertical"
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </div>
                    )}
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Field name="language">
                        {({ field, form }: FieldProps) => (
                          <NewSelect
                            label={t('creative.create_creative.Language')}
                            viewingOnly={!edit}
                            form={form}
                            field={field}
                            selectAttributes={{
                              name: 'language',
                              placeholder: t(
                                'creative.create_creative.select_from_list',
                              ),
                              options: currentLangOptions,
                              value: selectDefault(
                                `${formik.values.language}`,
                                currentLangOptions,
                              ),
                            }}
                            viewStyle="Vertical"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </TabPanel>
              )}
              {type !== 'MULTI_FORMAT' && type !== 'CLICKUNDER' && isOwner && (
                <TabPanel>
                  <div className={css.card__row}>
                    <div className={cn(css.card__col, 'full')}>
                      <Field name="algorithm_cpc.id">
                        {({ field, form }: FieldProps) => (
                          <NewSelect
                            label={t(
                              'creative.create_creative.optimization.algorithm',
                            )}
                            viewingOnly={!edit}
                            form={form}
                            field={field}
                            selectAttributes={{
                              placeholder: t(
                                'creative.create_creative.select_type',
                              ),
                              options: algorithmsSelect,
                              value: selectDefault(
                                `${formik.values.algorithm_cpc.id}`,
                                algorithmsSelect,
                              ),
                            }}
                            viewStyle="Vertical"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <animated.div style={showBlock} className={css.card__row}>
                    <div className={cn(css.card__col)}>
                      <PriceInput
                        label={t('creative.create_creative.optimization.min')}
                        error={
                          helperError('algorithm_cpc.min') ||
                          !!formik.errors.algorithm_cpc?.min
                        }
                        notField={!edit}
                        disabled={
                          formik.values.algorithm_cpc.id === 1 ||
                          formik.values.algorithm_cpc.id === '1'
                        }
                        errorTextNull={
                          formik.errors.algorithm_cpc?.min === 'true'
                        }
                        errorText={formik.errors.algorithm_cpc?.min}
                        numberFormatAttributes={{
                          decimalScale: 3,
                          maxLength: 18,
                          fixedDecimalScale: false,
                          value: formik.values.algorithm_cpc.min,
                          onValueChange: ({ floatValue }) => {
                            formik.setFieldValue(
                              'algorithm_cpc.min',
                              floatValue,
                            );
                          },
                          onFocus: () => {
                            if (formik.values.algorithm_cpc.min === 0) {
                              formik.setFieldValue('algorithm_cpc.min', null);
                            }
                          },
                        }}
                      />
                    </div>
                    <div className={cn(css.card__col)}>
                      <PriceInput
                        label={t('creative.create_creative.optimization.max')}
                        error={
                          helperError('algorithm_cpc.max') ||
                          !!formik.errors.algorithm_cpc?.max
                        }
                        notField={!edit}
                        disabled={
                          formik.values.algorithm_cpc.id === 1 ||
                          formik.values.algorithm_cpc.id === '1'
                        }
                        errorText={formik.errors.algorithm_cpc?.max}
                        numberFormatAttributes={{
                          decimalScale: 3,
                          maxLength: 18,
                          fixedDecimalScale: false,
                          value: formik.values.algorithm_cpc.max,
                          onValueChange: ({ floatValue }) => {
                            formik.setFieldValue(
                              'algorithm_cpc.max',
                              floatValue,
                            );
                          },
                          onFocus: () => {
                            if (formik.values.algorithm_cpc.max === 0) {
                              formik.setFieldValue('algorithm_cpc.max', null);
                            }
                          },
                        }}
                      />
                    </div>
                  </animated.div>
                </TabPanel>
              )}
            </Tabs>
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default Additional;
