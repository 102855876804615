import { useEffect, useState } from "react";

export type DirectionType = "LEFT" | "RIGHT" | null;

type TSwipeDetector = (ref: React.RefObject<HTMLDivElement>, setDirectionSwipe: React.Dispatch<React.SetStateAction<DirectionType>>) => void;

type TSwipePositionCursor = (ref: React.RefObject<HTMLDivElement>, setScrollOffset: React.Dispatch<React.SetStateAction<number>>) => void

type TUseEngineScroll = (ref: React.RefObject<HTMLDivElement>) => void;

// определяет происходит ли событие tach scroll в мобильном устройстве
export const swipeDetector: TSwipeDetector = (ref, setDirectionSwipe) => {
    if (ref.current) {
        ref.current.addEventListener("touchstart", handleTouchStart, false);
        ref.current.addEventListener("touchmove", handleTouchMove, false);
    }

    let xDown = 0;
    let yDown = 0;

    function handleTouchStart(evt: any) {
        const { clientX, clientY } = evt.touches[0];
        xDown = clientX;
        yDown = clientY;
    }

    function handleTouchMove(evt: any) {
        if (!xDown || !yDown) {
            return;
        }
        const { clientX, clientY } = evt.touches[0];
        const xDiff = xDown - clientX;
        const yDiff = yDown - clientY;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                setDirectionSwipe("RIGHT")
            } else {
                setDirectionSwipe("LEFT")
            }

        }
        xDown = 0;
        yDown = 0;
    }
};

// определяет положение курсора для синхронизации скролла
export const swipePositionCursor: TSwipePositionCursor = (ref, setScrollOffset) => {
    if (ref.current) {
        ref.current.addEventListener("touchstart", handleTouchStart, false);
        ref.current.addEventListener("touchmove", handleTouchMove, false);
    }

    let xDown = 0;

    function handleTouchStart(evt: any) {
        const { clientX } = evt.touches[0];
        xDown = clientX;
    }

    function handleTouchMove(evt: any) {
        if (!xDown) {
            return;
        }
        const { clientX } = evt.touches[0];
        setScrollOffset(xDown - clientX)
    }

};

// берет блок который надо скроллить и после срабатывания swipeDetector, 
// начинает двигать его в зависимости от swipePositionCursor
export const useEngineScroll: TUseEngineScroll = (ref) => {
    const [directionSwipe, setDirectionSwipe] = useState<DirectionType>(null);
    const [scrollOffset, setScrollOffset] = useState<number>(0);
    swipeDetector(ref, setDirectionSwipe);
    swipePositionCursor(ref, setScrollOffset);
    useEffect(() => {
        if (ref && ref.current) {
            if (directionSwipe === 'RIGHT') {
                // eslint-disable-next-line no-param-reassign
                ref.current.scrollLeft += scrollOffset / 7;
            }
            if (directionSwipe === 'LEFT') {
                // eslint-disable-next-line no-param-reassign
                ref.current.scrollLeft += scrollOffset / 7;
            }
        }
    }, [directionSwipe, scrollOffset]);
}
