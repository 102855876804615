import { IOkved } from 'jsonFiles/types';

export type TResult = {
  label: string;
  value: string;
  isAdditional: boolean;
};

export const removeTopLevelsOfJson = (arr: IOkved[]): TResult[] => {
  const result: TResult[] = [];

  const isValidCode = (code: string) => {
    const regex = /^[\d.]+$/;
    return code.length >= 5 && regex.test(code);
  };

  const recurse = (items: IOkved[]) => {
    items.forEach((item: IOkved) => {
      if (item.code && isValidCode(item.code)) {
        const resultObj: TResult = {
          label: item.code,
          value: item.name,
          isAdditional: true,
        };
        result.push(resultObj);
      }
      if (item.items && item.items.length > 0) {
        recurse(item.items);
      }
    });
  };

  recurse(arr);
  return result;
};
