import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  ParamProject,
  PProject,
  PProjectEdit,
  RProject,
  TLang,
  TLangGroup,
} from './types';
import { SagaUpdateVisial, SelectField } from '../../types/general';

export type ProjectState = {
  tempProject: FetchedData<RProject>;
  langGroup: TLangGroup;
  lang: TLang;
  logoType: FetchedData<string>;
  adBlock: {
    link: string;
    isBlocked: boolean;
  };
  isError404: boolean;
  userGroups: FetchedData<SelectField[]>;
  newYearTheme: boolean;
};

const initialState: ProjectState = {
  tempProject: genFetchedData<RProject>(null),
  langGroup: [
    { value: 'ru', label: 'Русский' },
    { value: 'en', label: 'English' },
    {
      value: 'de',
      label: 'Deutsch',
    },
    { value: 'tr', label: 'Türk' },
  ],
  lang: 'ru',
  logoType: genFetchedData<string>(null),
  adBlock: {
    link:
      // eslint-disable-next-line no-template-curly-in-string
      'https://ad.doubleclick.net/ddm/trackclk/N870197.3274918OMDPROGRAMMATICAD/B25528998.297309069;dc_trk_aid=490504960;dc_trk_cid=147011955;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=',
    isBlocked: false,
  },
  isError404: false,
  userGroups: genFetchedData<SelectField[]>(null),
  newYearTheme: false,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    fetchProject(state, action: PayloadAction<ProjectState['tempProject']>) {
      state.tempProject = action.payload;
    },
    setLang(state, action: PayloadAction<ProjectState['lang']>) {
      state.lang = action.payload;
    },
    setLogoType(state, action: PayloadAction<ProjectState['logoType']>) {
      state.logoType = action.payload;
    },
    setBlockedAd(
      state,
      action: PayloadAction<ProjectState['adBlock']['isBlocked']>,
    ) {
      state.adBlock.isBlocked = action.payload;
    },
    setError404(state, action: PayloadAction<ProjectState['isError404']>) {
      state.isError404 = action.payload;
    },
    setUsersGroups(state, action: PayloadAction<ProjectState['userGroups']>) {
      state.userGroups = action.payload;
    },
  },
});

export const fetchProjectAction = createAction<ParamProject>(
  'project/fetchProjectAction',
);
export const editProjectRequest = createAction<
  SagaUpdateVisial & { data: PProjectEdit }
>('project/editProjectRequest');

export const fetchProjectTechnicalWorks = createAction<PProject>(
  'project/fetchProjectTechnicalWorks',
);

export const fetchLangRequest = createAction('project/fetchLang');

export const changeLangRequest = createAction<TLang>(
  'project/changeLangRequest',
);

export const {
  fetchProject,
  setLogoType,
  setLang,
  setBlockedAd,
  setError404,
  setUsersGroups,
} = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
