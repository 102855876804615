import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { Form, FormikProvider } from 'formik';
import { Prompt, useHistory } from 'react-router';
import { useCreateContract } from 'domains/contract/model/hooks/useCreateContract';
import { useOrdList } from 'domains/counteragents/model/selectors';
import css from '../styles.module.scss';
import Info from '../components/Info';
import Counteragents from '../components/Counteragents';
import Data from '../components/Data';
import Additional from '../components/Additional';
import LoaderInfo from '../components/Info/LoaderInfo';

const CreateContract: FC = () => {
  const history = useHistory();
  const goBack = () => {
    history.push('/main/contracts');
  };
  const { t, i18n } = useTranslation();

  const { formik, handleChange, isPrompt, isLoadingCreate, options, error, contractList } =
    useCreateContract();

  const { isLoading: ordIsLoading, data: ordList } = useOrdList();

  const links = useMemo(
    () => [
      {
        title: t(`ord.contracts_title`),
        url: `/main/contracts`,
        type: 'home',
      },
      {
        title: t(`ord.creating_contact`),
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language],
  );

  return (
    <>
      <Helmet>
        <title>{t(`ord.creating_contact`)}</title>
      </Helmet>

      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`ord.creating_contact`)}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.container}>
          <CardList>
            <FormikProvider value={formik}>
              <Form>
                <Counteragents formik={formik} handleChange={handleChange} />
                <Data
                  formik={formik}
                  handleChange={handleChange}
                  contractList={contractList}
                />
                <Additional
                  formik={formik}
                  handleChange={handleChange}
                  options={options}
                  error={error}
                  mode="CREATE"
                />
                <div className={css.actions}>
                  <Button
                    title={t('campaigns_page.create_campaign.cancel_btn')}
                    transparent
                    height={40}
                    buttonAttributes={{
                      onClick: () => goBack(),
                      type: 'button',
                    }}
                  />
                  <Button
                    title={t('campaigns_page.create_campaign.create_btn')}
                    height={40}
                    buttonAttributes={{
                      disabled:
                        isLoadingCreate || !formik.isValid || error.isError,
                      onClick: () => formik.handleSubmit(),
                      type: 'button',
                    }}
                  />
                </div>
              </Form>
            </FormikProvider>
          </CardList>
          <CardList>
            {ordIsLoading ? (
              <LoaderInfo mode="create" />
            ) : (
              <Info mode="create" formik={formik} ordList={ordList} />
            )}
          </CardList>
        </div>
      </div>
      <Prompt when={isPrompt} message={t('just_words.exit_page_currect')} />
    </>
  );
};

export default CreateContract;
