import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import PriceInput from 'components/UI/PriceInput';
import LabelField from 'components/UI/LabelField';
import InputAutoSave from 'components/UI/InputAutoSave';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import cn from 'classnames';
import DateInput from 'components/UI/DateInput';
import moment from 'moment';
import { ContractFormikProps } from 'domains/contract/model/types';
import { InputLengthContract } from 'domains/contract/model/consts/inputLengthCounteragent';
import NewSelect from 'components/UI/NewSelect';
import { transformContractList } from 'domains/contract/model/hooks/contractUtils';
import { ContractsList } from 'domains/contracts/model/types';
import { selectDefault } from 'utils/selectDefault';
import css from '../styles.module.scss';
import i18n from '../../../../../i18n';

interface IDataProps {
  formik: FormikProps<ContractFormikProps>;
  handleChange: (
    name: string,
    value: string | number | boolean | undefined,
  ) => void;
  contractList?: ContractsList | null;
}

const Data: FC<IDataProps> = ({ formik, handleChange, contractList }) => {


  const contractTypeOptions = useMemo(
    () => [
      {
        label: i18n.t(`ord.intermediary_contract`),
        value: 'Intermediary',
      },
      {
        label: i18n.t(`ord.service_contract`),
        value: 'Original',
      },
      {
        label: i18n.t(`ord.additional_agreement`),
        value: 'Additional',
      },
      {
        label: i18n.t(`ord.self_advertisement`),
        value: 'SelfPromotion',
      },
    ],
    [i18n.language],
  );

  const contractItemTypes = useMemo(
    () => [
      {
        label: i18n.t(`ord.representation`),
        value: 'Representation',
      },
      {
        label: i18n.t(`ord.distribution_org`),
        value: 'DistributionOrganization',
      },
      {
        label: i18n.t(`ord.intermediation`),
        value: 'Mediation',
      },
      {
        label: i18n.t(`ord.distribution`),
        value: 'Distribution',
      },
      {
        label: i18n.t(`ord.other`),
        value: 'Other',
      },
    ],
    [i18n.language],
  );
  const { t } = useTranslation();

  return (
    <Card isUseAccess className={cn(css.card, css.data)} noHover>
      <h3 className={css.title}>{t(`ord.contract_data`)}</h3>
      <InputAutoSave
        label={t(`ord.contract_number`)}
        value={formik.values.number || ''}
        name="number"
        maxLength={InputLengthContract.common}
        placeholder={t(`ord.enter_number`)}
        setFieldValue={handleChange}
        isError={!!formik.errors.number}
        errorText={formik.errors.number}
      />
      <LabelField label={t(`ord.contract_date`)}>
        <Field name="date" placeholder={`${t(`ord.enter_date`)}...`}>
          {({ field, form }: FieldProps<FormikValues>) => (
            <div className={css.calendar}>
              <DateInput
                field={field}
                form={form}
                isValidateField
                error={!!formik.errors.date}
                errorText={String(formik.errors.date)}
                disabledDays={{
                  after: moment(new Date()).toDate(),
                }}
                theme="horizontal"
              />
            </div>
          )}
        </Field>
      </LabelField>
      <LabelField label={t(`ord.contract_type`)}>
        <Field name="type">
          {({ field, form }: FieldProps) => (
            <NewSelect
              form={form}
              field={field}
              validateHandler={formik.validateField}
              error={!!formik.errors.type}
              errorText={formik.errors.type}
              onCustomChange={() => formik.setFieldValue('parent', '')}
              selectAttributes={{
                isDisabled: false,
                value: contractTypeOptions.find(
                  (opt) => opt.value === formik.values.type,
                ),
                options: contractTypeOptions,
                placeholder: t(`project_page.forms.not_selected`),
              }}
              heightCustom={32}
              viewStyle="Horizontal"
            />
          )}
        </Field>
      </LabelField>
      {contractList && formik.values.type === 'Additional' && (
        <LabelField label={t(`ord.parental_contract`)}>
          <Field name="parent">
            {({ field, form }: FieldProps) => (
              <NewSelect
                form={form}
                field={field}
                validateHandler={formik.validateField}
                error={!!formik.errors.parent}
                errorText={formik.errors.parent}
                selectAttributes={{
                  isDisabled: false,
                  value: selectDefault(
                    formik.values.parent || '',
                    transformContractList(contractList) || [],
                  ),
                  options: transformContractList(contractList),
                  placeholder: t(`project_page.forms.not_selected`),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </LabelField>
      )}
      <LabelField label={t(`ord.information_on_contract`)}>
        <Field name="subjectType">
          {({ field, form }: FieldProps) => (
            <NewSelect
              form={form}
              field={field}
              validateHandler={formik.validateField}
              error={!!formik.errors.subjectType}
              errorText={formik.errors.subjectType}
              selectAttributes={{
                isDisabled: false,
                value: contractItemTypes.find(
                  (opt) => opt.value === formik.values.subjectType,
                ),
                options: contractItemTypes,
                placeholder: t(`project_page.forms.not_selected`),
              }}
              heightCustom={32}
              viewStyle="Horizontal"
            />
          )}
        </Field>
      </LabelField>
      <LabelField
        label={t(`ord.contract_amount`)}
        classname={cn(css.nds_wrapper, {
          _isError: !!formik.errors.amount,
        })}
      >
        <div className={css.nds}>
          <PriceInput
            decimalScale={2}
            error={!!formik.errors.amount}
            errortext={formik.errors.amount}
            maxLength={InputLengthContract.common}
            value={Number(formik.values.amount)}
            hasZero
            onValueChange={({ floatValue }) => {
              handleChange('amount', floatValue);
            }}
            theme="horizontal"
          />
        </div>
      </LabelField>
    </Card>
  );
};

export default Data;
