import { daysDeclensionFunc } from 'utils/daysDeclensionFunc';
import { format } from 'date-fns';
import { THistoryResponse } from 'domains/historyChanges/types';
import { historyDescription } from '../../utils/historyDescription';
import { describePretargetings } from './describePretargetings';
import { describeTargetings } from './describeTargetings';
import { ILocalHistoryData } from './types';
import {
  algorithmCpcDescription,
  categoriesDescription,
  creativeDataDescription,
  fillTagsArr,
  transformeLimitsRowData,
} from './utils';
import { describeReports } from './describeReports';
import { languageDescription } from '../../utils/languageDescription';
import i18n from '../../../../../i18n';

export // функция расшифровки условия в зависимости от пришедших в запросе полей
const describeJsonItemData = (
  item: [string, any],
  data: THistoryResponse,
  historyDataForLocalHistory: ILocalHistoryData[],
  resultChangesArr: string[],
  resultStatusArr: string[],
  action: string,
  type: string
): void => {
  if (historyDescription[item[0]]) {
    if (item[0] === 'container') {
      if (item[1].cid || item[1].cid === '') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]].cid,
          value: item[1].cid === '' ? 'Очищено' : item[1].cid,
        });
        resultStatusArr.push(historyDescription[item[0]].cid);
        resultChangesArr.push(item[1].cid === '' ? 'Очищено' : item[1].cid);
        return;
      }
      if (item[1].save_strk_to_cookie || item[1].save_strk_to_cookie === 0) {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]].save_strk_to_cookie,
          value: item[1].save_strk_to_cookie === 1 ? 'Да' : 'Нет',
        });
        resultStatusArr.push(historyDescription[item[0]].save_strk_to_cookie);
        resultChangesArr.push(item[1].save_strk_to_cookie === 1 ? 'Да' : 'Нет');
        return;
      }
    }
    if (item[0] === 'targetings') {
      describeTargetings(
        item[1],
        historyDataForLocalHistory,
        resultChangesArr,
        resultStatusArr,
      );
      return;
    }
    if (item[0] === 'pretargeting' || item[0] === 'brand_safety') {
      describePretargetings(
        item[0],
        item[1],
        data.data,
        type,
        historyDataForLocalHistory,
        resultChangesArr,
        resultStatusArr,
      );
      return;
    }
    if (item[0] === 'title') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]][type],
        value: item[1],
      });
      resultStatusArr.push(historyDescription[item[0]][type]);
      resultChangesArr.push(item[1]);
      return;
    }
    if (item[0] === 'status') {
      historyDataForLocalHistory.push({
        key: '',
        value: historyDescription.statusData[type][item[1]],
      });
      resultStatusArr.push(historyDescription[item[0]][type]);
      resultChangesArr.push(historyDescription.statusData[type][item[1]]);
      return;
    }
    if (item[0] === 'tags') {
      const tagsArr: string[] = [];
      const tagsData = Object.entries(item[1]);

      resultStatusArr.push(historyDescription[item[0]][type]);

      tagsData.forEach((tag) => {
        fillTagsArr(tag as string[], resultChangesArr);
        fillTagsArr(tag as string[], tagsArr);
      });
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]][type],
        value: tagsArr,
      });
      return;
    }
    if (item[0] === 'config_values') {
      if (item[1]) {
        const objKeys = Object.keys(item[1]);
        objKeys.forEach((key) => {
          resultStatusArr.push(key);
          let value = item[1][key];
          if (value === 'true') {
            value = 'Включено';
          }
          if (value === 'false') {
            value = 'Выключено';
          }
          resultChangesArr.push(`${value === '' ? 'Поле очищено' : value}`);
          historyDataForLocalHistory.push({
            key,
            value: `${value === '' ? 'Поле очищено' : value}`,
          });
        });
      }
      return;
    }

    if (item[0] === 'data' && item[1].audit) {
      const optionalSettings: Record<string, string> = {
        show: 'video_show',
        show2: 'video_show2',
        click: 'video_click',
        mute: 'video_mute',
        pause: 'video_pause',
        skip: 'video_skip',
        unmute: 'video_unmute',
        view_0: 'video_view_0',
        view_25: 'video_view_25',
        view_50: 'video_view_50',
        view_75: 'video_view_75',
        view_100: 'video_view_100',
        close: 'video_close',
        collapse: 'video_collapse',
        exitfs: 'video_exitfs',
        expand: 'video_expand',
        fullscreen: 'video_fullscreen',
        resume: 'video_resume',
        rewind: 'video_rewind',
        sprogresshow: 'video_sprogresshow',
      };

      const { audit } = item[1];
      Object.keys(audit)
        .filter((it: string) => optionalSettings[it])
        .forEach((i) => {
          historyDataForLocalHistory.push({
            key: i18n.t(`creative.video.fields.${optionalSettings[i]}`),
            value: item[1].audit[i] ? item[1].audit[i] : 'Поле очищено',
          });
          resultChangesArr.push(
            item[1].audit[i] ? item[1].audit[i] : 'Поле очищено',
          );
          resultStatusArr.push(
            i18n.t(`creative.video.fields.${optionalSettings[i]}`),
          );
        });
      return;
    }

    if (item[0] === 'data' && 'bundle' in item[1]) {
      historyDataForLocalHistory.push({
        key: historyDescription.bundle,
        value: item[1].bundle ? item[1].bundle : 'Поле очищено',
      });
      resultChangesArr.push(item[1].bundle ? item[1].bundle : 'Поле очищено');
      resultStatusArr.push(historyDescription.bundle);
      return;
    }

    /** Временно скрыли, пока неясно как быть с отсуствием owner у пользователя */
    // if (item[0] === 'hidden_statistic') {
    //   historyDataForLocalHistory.push({
    //     key: historyDescription.hidden_statistic,
    //     value: `${item[1] ? 'Да' : 'Нет'}`
    //   })
    //   resultChangesArr.push(`${item[1] ? 'Да' : 'Нет'}`);
    //   resultStatusArr.push(historyDescription.hidden_statistic)
    //   return;
    // }

    if (item[0] === 'contract_uuid') {
      historyDataForLocalHistory.push({
        key: historyDescription.contract_uuid,
        value: item[1],
      });
      resultChangesArr.push(item[1]);
      resultStatusArr.push(historyDescription.contract_uuid);
      return;
    }

    if (item[0] === 'lead_wait_time') {
      historyDataForLocalHistory.push({
        key: historyDescription.lead_wait_time,
        value: daysDeclensionFunc(item[1]),
      });
      resultChangesArr.push(daysDeclensionFunc(item[1]));
      resultStatusArr.push(historyDescription.lead_wait_time);
      return;
    }

    /** Временно скрыли, до доработки бэкенда, присылает неверные значения */
    // if (item[0] === 'statistic_fields') {
    //   let result = '';
    //   Object.keys(item[1]).forEach((i, index) => {
    //     result += `${i} ${item[1][i].checked ? 'Включен' : 'Выключен'}${
    //       index === Object.keys(item[1]).length - 1 ? '' : ', '
    //     }`;
    //   });
    //   historyDataForLocalHistory.push({
    //     key: historyDescription.statistic_fields,
    //     value: result,
    //   });
    //   resultChangesArr.push(result);
    //   resultStatusArr.push(historyDescription.statistic_fields);
    //   return;
    // }

    if (item[0] === 'method_recalculating_statistics') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: historyDescription[item[1]],
      });
      resultChangesArr.push(historyDescription[item[1]]);
      resultStatusArr.push(historyDescription[item[0]]);
      return;
    }

    if (item[0] === 'limits') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: `${transformeLimitsRowData(
          item[1],
          'click',
          i18n.t(`campaigns_page.campaign_settings.Clicks`),
        )}${transformeLimitsRowData(
          item[1],
          'click_per_user',
          'Клики (пользователь)',
        )}${transformeLimitsRowData(
          item[1],
          'show',
          'Показы',
        )}${transformeLimitsRowData(
          item[1],
          'show_per_user',
          i18n.t(`campaigns_page.campaign_settings.per_user`),
        )}${transformeLimitsRowData(
          item[1],
          'budget_raw',
          i18n.t(`campaigns_page.campaign_settings.Budget_RAW`),
        )}${transformeLimitsRowData(
          item[1],
          'budget',
          i18n.t(`campaigns_page.campaign_settings.Budget`),
        )}${transformeLimitsRowData(
          item[1],
          'budget_per_user',
          'Бюджет (пользователь)',
        )}${transformeLimitsRowData(item[1], 'use_adriver_frequency_cap')}`
      });

      resultChangesArr.push(
        `${transformeLimitsRowData(
          item[1],
          'click',
          i18n.t(`campaigns_page.campaign_settings.Clicks`),
        )}${transformeLimitsRowData(
          item[1],
          'click_per_user',
          'Клики (пользователь)',
        )}${transformeLimitsRowData(
          item[1],
          'show',
          'Показы',
        )}${transformeLimitsRowData(
          item[1],
          'show_per_user',
          i18n.t(`campaigns_page.campaign_settings.per_user`),
        )}${transformeLimitsRowData(
          item[1],
          'budget_raw',
          i18n.t(`campaigns_page.campaign_settings.Budget_RAW`),
        )}${transformeLimitsRowData(
          item[1],
          'budget',
          i18n.t(`campaigns_page.campaign_settings.Budget`),
        )}${transformeLimitsRowData(
          item[1],
          'budget_per_user',
          'Бюджет (пользователь)',
        )}${transformeLimitsRowData(item[1], 'use_adriver_frequency_cap')}`,
      );

      resultStatusArr.push(historyDescription[item[0]]);
      
      return;
    }
    if (item[0] === 'date_start' || item[0] === 'date_end') {
      if (item[1].date) {
        historyDataForLocalHistory.push({
          key:
            item[0] === 'date_start'
              ? 'Дата начала кампании'
              : 'Дата окончания кампании',
          value: format(new Date(item[1].date), 'dd.MM.yyyy'),
        });
        resultChangesArr.push(format(new Date(item[1].date), 'yyyy.MM.dd'));
        resultStatusArr.push(
          item[0] === 'date_start'
            ? 'Дата начала кампании'
            : 'Дата окончания кампании',
        );
      }
      return;
    }
    if (item[0] === 'cpa_optimizations') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: item[1] ? 'Включена' : 'Выключена',
      });
      resultChangesArr.push(item[1] ? 'Включена' : 'Выключена');
      resultStatusArr.push(historyDescription[item[0]]);
      return;
    }
    if (item[0] === 'check_ERID') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: item[1] ? 'Включена' : 'Выключена',
      });
      resultChangesArr.push(item[1] ? 'Включена' : 'Выключена');
      return;
    }
    if (item[0] === 'reports') {
      resultStatusArr.push(historyDescription[item[0]]);
      describeReports(item[1], historyDataForLocalHistory, resultChangesArr);
      return;
    }
    if (item[0] === 'categories') {
      historyDataForLocalHistory.push({
        key: '',
        value: categoriesDescription(item[1], resultChangesArr),
      });
      resultStatusArr.push(historyDescription[item[0]]);
      return;
    }
    if (item[0] === 'language') {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: languageDescription[item[1]],
      });
      resultChangesArr.push(languageDescription[item[1]]);
      resultStatusArr.push(historyDescription[item[0]]);
      return;
    }
    if (item[0] === 'data') {
      resultStatusArr.push(historyDescription[item[0]]);
      creativeDataDescription(
        item[1],
        historyDataForLocalHistory,
        resultChangesArr,
      );
      return;
    }
    if (item[0] === 'algorithm_cpc') {
      if (item[1]) {
        algorithmCpcDescription(
          item[1],
          historyDataForLocalHistory,
          resultChangesArr,
        );
        resultStatusArr.push(historyDescription[item[0]]);
      }
      return;
    }
    if (item[1] === '' || item[1] === null) {
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: 'Поле очищено',
      });
      resultStatusArr.push(historyDescription[item[0]]);
      resultChangesArr.push('Поле очищено');
      return;
    }

    historyDataForLocalHistory.push({
      key: historyDescription[item[0]],
      value: `${String(item[1])}`,
    });
    resultStatusArr.push(historyDescription[item[0]]);
    resultChangesArr.push(String(item[1]));

    return;
  }

  if (action === 'EDIT' && item[0] === 'commission_agency') {
    historyDataForLocalHistory.push({
      key: 'Комиссия агентства %',
      value: ` ${item[1]}`,
    });
    resultChangesArr.push(` ${item[1]}`);
    resultStatusArr.push('Комиссия агентства %');
    return;
  }

  if (action === 'EDIT' && item[0] === 'commission_self_service') {
    historyDataForLocalHistory.push({
      key: 'Комиссия Self-Service %',
      value: ` ${item[1]}`,
    });
    resultChangesArr.push(` ${item[1]}`);
    resultStatusArr.push('Комиссия Self-Service %');
    return;
  }

  resultStatusArr.push('EDIT');
};
