import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Loader from 'components/UI/Loader';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import { useParams } from 'react-router-dom';
import { TID } from 'types/general';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { isDisableEridToggle } from 'utils/isDisableEridToggle';
import InputAutoSave from 'components/UI/InputAutoSave';
import { createConfiValues } from 'utils/createConfigValues';
import { isFieldInConfig } from 'utils/isFieldInConfig';
import deepEqual from 'deep-equal';
import Help from 'components/Help';
import CollapseCard from 'components/UI/CollapseCard';
import { everyGroups } from 'utils/statics/everyGroups';
import { useUserInfo } from 'domains/user/model/selectors';
import {
  useCreativeDetailInfo,
  useCreativeTypes,
} from '../../../model/selectors';
import ErrorReport from '../../../../../components/Errors/Reports';
import css from './styles.module.scss';
import { setEditCreativeRequest, setExternalIdRequest } from '../../../reduser';
import Pretargeting from '../../../../../components/Pretargeting';
import { useCreativeSettings } from '../hooks/useCreativeSettings';

type Values = {
  Ids: string;
  check_ERID: boolean;
  config_values: Record<string, string | boolean>;
};

const checkEridArr = [
  'HTML',
  'VIDEO_INLINE',
  'COMPANION',
  'VIDEO_VAST',
  'TEASER',
  'CLICKUNDER',
];

const Settings: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<TID>();
  const { data: user } = useUserInfo();

  const {
    pretargeting,
    isExtend,
    handlerSetCreativePretargeting,
    handlerRemoveCreativePretargeting,
  } = useCreativeSettings();

  const {
    LTU,
    isLoading,
    data: creativeDetail,
    error,
  } = useCreativeDetailInfo();

  const { data: creativeTypesArr } = useCreativeTypes();
  const config = creativeTypesArr?.find(
    (item) => item.key === creativeDetail?.type,
  );
  const configArr = (config && Object.values(config.config_fields)) || [];
  const fakeId = isFieldInConfig(configArr, 'fakeid');

  const configValues = useMemo(
    () =>
      createConfiValues(configArr, creativeDetail?.config_values || {}, [
        'fakeid',
      ]),
    [configArr, creativeDetail?.config_values],
  );

  const [initialValues, setInitialValues] = useState<Values>({
    Ids: '',
    check_ERID: false,
    config_values: configValues,
  });
  const [defIds, setterIds] = useState<string>('');
  const [checkERID, setterCheckErid] = useState<boolean>(false);

  useEffect(() => {
    if (creativeDetail === null) {
      return;
    }
    if (isLoading) {
      return;
    }
    setterIds(
      creativeDetail.external_ids.length ? creativeDetail.external_ids : '',
    );
    setterCheckErid(!!creativeDetail.check_ERID.CREATIVE);
    setInitialValues({
      Ids: creativeDetail.external_ids.length
        ? creativeDetail.external_ids
        : '',
      check_ERID: !!creativeDetail.check_ERID?.CREATIVE,
      config_values: configValues,
    });
  }, [creativeDetail, LTU, fakeId?.id && configValues[fakeId.id]]);

  const onSubmit = useCallback(
    (values: Values) => {
      if (typeof id === 'undefined') {
        return;
      }
      if (defIds !== values.Ids) {
        dispatch(
          setExternalIdRequest({
            id,
            Ids: values.Ids,
            blockTitle:
              checkERID !== values.check_ERID
                ? null
                : t('creative.settings.ext_sour'),
          }),
        );
      }
      if (
        checkERID !== values.check_ERID ||
        !deepEqual(values.config_values, configValues)
      ) {
        dispatch(
          setEditCreativeRequest({
            data: {
              xxhash: id,
              check_ERID: values.check_ERID,
              config_values: values.config_values,
            },
            visual: {
              setter: () => null,
              alertText: t('creative.settings.ext_sour'),
              value: false,
            },
          }),
        );
      }
    },
    [defIds, checkERID, id],
  );

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={css.table}>
        <Helmet>
          <title>{t('creative.settings.set_tit')}</title>
        </Helmet>

        <div className="container">
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialValues}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <CardsList>
                <Form>
                  <Pretargeting
                    showExtended
                    isExtend={isExtend}
                    preTargeting={pretargeting}
                    handlerSave={handlerSetCreativePretargeting}
                    handlerResetExtent={handlerRemoveCreativePretargeting}
                  />
                  <Card noHover className={css.card}>
                    <CollapseCard
                      updateHeight={[Boolean(fakeId)]}
                      title={
                        <h3>
                          {t(
                            'campaigns_page.campaign_additional.special.title',
                          )}
                        </h3>
                      }
                    >
                      <div className={css.card__form}>
                        <Input
                          label={t('creative.settings.ids')}
                          isTextarea
                          inputAttributes={{
                            name: 'Ids',
                            style: { minHeight: 136 },
                            value: values.Ids,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            placeholder: t('creative.settings.enter_date'),
                          }}
                          tooltip={{
                            text: t('creative.settings.id_from_ss'),
                          }}
                        />
                        {creativeDetail &&
                          checkEridArr.includes(creativeDetail.type) && (
                            <div>
                              <div className={css.label}>
                                {t(
                                  'clients_page.client_edit.moderation_creatives',
                                )}
                              </div>
                              <ToggleSwitch
                                disabled={isDisableEridToggle(
                                  creativeDetail,
                                  'CREATIVE',
                                )}
                                onChange={() =>
                                  setFieldValue(
                                    'check_ERID',
                                    !values.check_ERID,
                                  )
                                }
                                checked={values.check_ERID}
                                activateText={t(
                                  'creative.widget_text.check_ERID',
                                )}
                                deactivateText={t(
                                  'creative.widget_text.check_ERID',
                                )}
                              />
                            </div>
                          )}
                        {fakeId && (
                          <div className={css.config_field}>
                            <InputAutoSave
                              label={fakeId.title}
                              value={
                                values.config_values[fakeId.id]
                                  ? String(values.config_values[fakeId.id])
                                  : ''
                              }
                              name={`config_values.${fakeId.id}`}
                              maxLength={120}
                              setFieldValue={setFieldValue}
                              isError={false}
                            />
                            {fakeId.description !== '' && (
                              <Help
                                title=""
                                helpattributes={{
                                  text: fakeId.description,
                                }}
                              />
                            )}
                          </div>
                        )}
                        {everyGroups(user, 'test') && (
                          <div className={css.link}>
                            <div className={css.name}>
                              {`${t(
                                'campaigns_page.campaign_settings.additional.links.table.creative_ch_type',
                              )}:`.toUpperCase()}

                            </div>
                            <div className={css.value}>
                              {creativeDetail?.creative_ch_type || '-'}
                            </div>
                          </div>
                        )}
                        {everyGroups(user, 'test') && (
                          <div className={css.link}>
                            <div className={css.name}>
                              {`${t(
                                'campaigns_page.campaign_settings.additional.links.table.pixel_type',
                              )}:`.toUpperCase()}
                            </div>
                            <div className={css.value}>
                              {creativeDetail?.pixel_type || '-'}
                            </div>
                          </div>
                        )}
                      </div>
                    </CollapseCard>
                  </Card>

                  <div className={css.actions}>
                    <Button
                      title={t('save_btn')}
                      height={40}
                      buttonAttributes={{
                        type: 'submit',
                        disabled: creativeDetail?.type === 'YANDEX',
                      }}
                    />
                  </div>
                </Form>
              </CardsList>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default Settings;
