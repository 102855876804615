import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { Form, FormikProvider } from 'formik';
import { Prompt, useHistory } from 'react-router';
import { useUpdateContract } from 'domains/contract/model/hooks/useUpdateContract';
import ErrorReport from 'components/Errors/Reports';
import { useOrdList } from 'domains/counteragents/model/selectors';
import { someGroups } from 'utils/statics/everyGroups';
import { useUserInfo } from 'domains/user/model/selectors';
import { useSetLastIdContract } from 'utils/useLastIdHelpers/useLastIdContract';
import css from '../styles.module.scss';
import Info from '../components/Info';
import Counteragents from '../components/Counteragents';
import Data from '../components/Data';
import Additional from '../components/Additional';
import LoaderCounteragents from '../components/Counteragents/LoaderCounteragents';
import LoaderAdditional from '../components/Additional/LoaderAdditional';
import LoaderData from '../components/Data/LoaderData';
import LoaderInfo from '../components/Info/LoaderInfo';
import Moderation from '../components/Moderation';
import GridCompaign from '../components/GridCompaign';

const EditContract: FC = () => {
  const { data: user } = useUserInfo();
  const history = useHistory();
  const goBack = () => {
    history.push('/main/contracts');
  };
  const { t, i18n } = useTranslation();

  const {
    formik,
    handleChange,
    isPrompt,
    isLoadingContractData,
    contractData,
    isLoadingUpdate,
    externalId,
    error,
    options,
    getContractData,
    contractList
  } = useUpdateContract();

  const { isLoading: ordIsLoading, data: ordList } = useOrdList();

  const links = useMemo(
    () => [
      {
        title: t(`ord.contracts_title`),
        url: `/main/contracts`,
        type: 'home',
      },
      {
        title: contractData?.number || '',
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language, contractData?.number],
  );

  useSetLastIdContract(contractData?.status);

  if (error.code === 90) {
    return (
      <ErrorReport error={{ code: 90, isError: true, message: 'error' }} />
    );
  }

  return (
    <>
      <Helmet>
        <title>{contractData?.number}</title>
      </Helmet>
      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.status}>
            <h1 className={css.title}>{contractData?.number}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.container}>
          <CardList>
            <FormikProvider value={formik}>
              <Form>
                {isLoadingContractData || ordIsLoading ? (
                  <>
                    <LoaderCounteragents />
                    <LoaderData />
                    <LoaderAdditional />
                  </>
                ) : (
                  <>
                    <Counteragents
                      formik={formik}
                      handleChange={handleChange}
                      mode="edit"
                    />
                    <Data
                      formik={formik}
                      handleChange={handleChange}
                      contractList={contractList}
                    />
                    <Additional
                      formik={formik}
                      handleChange={handleChange}
                      options={options}
                      error={error}
                      mode="EDIT"
                    />
                  </>
                )}
                <div className={css.actions}>
                  <Button
                    title={t('campaigns_page.create_campaign.cancel_btn')}
                    transparent
                    height={40}
                    buttonAttributes={{
                      onClick: () => goBack(),
                      type: 'button',
                    }}
                  />
                  <Button
                    title={t('save_btn')}
                    height={40}
                    buttonAttributes={{
                      disabled:
                        isLoadingUpdate || !formik.isValid || error.isError,
                      onClick: () => formik.handleSubmit(),
                      type: 'button',
                    }}
                  />
                </div>
              </Form>
            </FormikProvider>
          </CardList>
          <CardList>
            {isLoadingContractData || ordIsLoading ? (
              <LoaderInfo mode="edit" />
            ) : (
              <div>
                <Info
                  mode="edit"
                  ordList={ordList}
                  formik={formik}
                  externalId={externalId}
                />
                {contractData && (
                  <Moderation
                    status={contractData.status}
                    getContractData={getContractData}
                  />
                )}
                {someGroups(user, 'owner_ord') && (
                  <GridCompaign isLoadingContractData={isLoadingContractData} />
                )}
              </div>
            )}
          </CardList>
        </div>
      </div>
      <Prompt when={isPrompt} message={t('just_words.exit_page_currect')} />
    </>
  );
};

export default EditContract;
